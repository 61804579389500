import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadImageApi } from "../../services/movieupload";
import CloudUploadIcon from "../../images/UploadMoviePage/Featuredicon.svg";
import { Typography, Card, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Toaster, toast } from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";

const Fileuplaod = ({ onUpload, uploadedUrl }) => {
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(uploadedUrl);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    // Check if the file is an image
    // if (!file.type.startsWith("image/")) {
    //   toast.error("Unsupported file type. Please upload an image.");
    //   return;
    // }

    // Check if the file size exceeds 5MB
    const maxSizeInBytes = 5 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      toast.error("File size exceeds the 5MB limit.");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageSrc(reader.result);
      handleImageUpload(file);
    };
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
      "application/pdf": [".pdf"],
    },
  });

  const handleImageUpload = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      const uploadResponse = await uploadImageApi(formData);
      if (uploadResponse.status === 200) {
        if (onUpload) {
          onUpload(uploadResponse.data.data.filePath);
        }
        toast.success("Image uploaded successfully.");
      } else {
        toast.error("Failed to upload image. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred while uploading the image.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Toaster />
      {!uploadedUrl ? (
        <Card
          className="upload-card"
          sx={{ backgroundColor: "#000", my: 0 }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div style={{ textAlign: "center" }}>
            {loading ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <>
                <img
                  src={CloudUploadIcon}
                  alt=""
                  onClick={open}
                  style={{ height: "60px", width: "60px" }}
                />
                <Typography
                  variant="body1"
                  component="p"
                  className="upload-card-text"
                  onClick={open}
                >
                  Select file to upload
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  style={{ color: "white" }}
                  onClick={open}
                >
                  or drag and drop
                </Typography>
              </>
            )}
          </div>
        </Card>
      ) : (
        <Card
          className="upload-card"
          sx={{ backgroundColor: "#000" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div style={{ textAlign: "center" }}>
            {loading ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <>
                <img
                  src={CloudUploadIcon}
                  alt=""
                  onClick={open}
                  style={{ height: "60px", width: "60px" }}
                />
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography
                    variant="body1"
                    component="p"
                    className="upload-card-text"
                    onClick={open}
                  >
                    Uploaded Successfully
                  </Typography>
                  <CheckCircleIcon sx={{ color: "green", marginLeft: "8px" }} />
                </Box>
                <Typography
                  variant="body2"
                  component="p"
                  style={{ color: "white" }}
                  onClick={open}
                >
                  Change Picture
                </Typography>
              </>
            )}
          </div>
        </Card>
      )}
    </div>
  );
};

export default Fileuplaod;
