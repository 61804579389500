import React, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContactImg from "../../images/Contact/contact_img.jpeg";
import "../../scss/Contact.scss";
import { useNavigate } from "react-router-dom";

const Faq = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid container className="contact-body">
      <Grid container>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            py: { xs: "1rem", md: 7 },
            px: { xs: "1rem", md: 10 },
          }}
        >
          <Typography variant="para" className="contact-title">
            Frequently Asked Questions
          </Typography>
          <br />
          {/* <Typography variant="para" className="contact-sub-title">
                        If you have any more questions or need further information, please feel free to <br /> contact us. We’re here to help.
                    </Typography> */}
          <hr />
          <div sx={{ my: 5 }}>
            <Accordion
              sx={{ mt: 2 }}
              className="faq_card"
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="faq-card"
              >
                <Typography className="accordion_text1">
                  What is special about CINET?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion_text2">
                  CINET offers the unique feature of releasing movies online
                  ‘simultaneously’ with their theatrical release. Viewers must
                  purchase a ticket to watch the movie. The ticket is valid for
                  one-time viewing, similar to a theatre ticket. If the viewer
                  wants to watch the movie again, they must buy another ticket
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ mt: 2 }}
              className="faq_card"
              expanded={expanded === "panel2"}
              onChange={handleAccordionChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                aria-controls="panel2-content"
                id="panel2-header"
                className="faq-card"
              >
                <Typography className="accordion_text1">
                  What is the benefit of a ‘simultaneous release’?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion_text2">
                  Filmmakers often aim for a grand release, investing heavily in
                  publicity. When a movie is released in theatres, only local
                  audiences can view it, potentially missing out on viewers who
                  can't access the theatres. By making your movie available
                  through CINET to audiences worldwide, you can fully capitalize
                  on your publicity efforts and reach a global audience.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ mt: 2 }}
              className="faq_card"
              expanded={expanded === "panel3"}
              onChange={handleAccordionChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                aria-controls="panel3-content"
                id="panel3-header"
                className="faq-card"
              >
                <Typography className="accordion_text1">
                  Do you show only selective languages of movies?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion_text2">
                  No, you can upload movies in any language. However, we
                  prioritize Indian languages.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ mt: 2 }}
              className="faq_card"
              expanded={expanded === "panel4"}
              onChange={handleAccordionChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                aria-controls="panel4-content"
                id="panel4-header"
                className="faq-card"
              >
                <Typography className="accordion_text1">
                  How should I handle publicity?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion_text2">
                  You don’t need to do anything extra. When promoting your movie
                  through newspapers, social media, TV interviews/ads, posters,
                  or hoardings, mention that the movie is available on CINET. In
                  newspapers, prominently display the CINET logo along with the
                  list of releasing theatres. CINET will be a major platform
                  with significant revenue potential.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ mt: 2 }}
              className="faq_card"
              expanded={expanded === "panel5"}
              onChange={handleAccordionChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                aria-controls="panel5-content"
                id="panel5-header"
                className="faq-card"
              >
                <Typography className="accordion_text1">
                  Who decides the streaming schedule?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion_text2">
                  As the filmmaker, you decide when to release your movie. If
                  you have set dates for regular theatres, you can release the
                  movie on CINET on the same day. If the movie is not being
                  released in regular theatres, you can choose any auspicious
                  day to start the show. Your movie will be available on CINET
                  for a month.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ mt: 2 }}
              className="faq_card"
              expanded={expanded === "panel6"}
              onChange={handleAccordionChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                aria-controls="panel6-content"
                id="panel6-header"
                className="faq-card"
              >
                <Typography className="accordion_text1">
                  Who decides the ticket price?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion_text2">
                  You set the ticket price for your movie. It can be priced at
                  50 rupees or 500 rupees, as you prefer. Once the ticket price
                  is set, it cannot be changed until the end of the schedule
                  period. Tickets are valid for one-time viewing only. The movie
                  cannot be downloaded, and if a viewer wants to watch it again,
                  they must purchase another ticket.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ mt: 2 }}
              className="faq_card"
              expanded={expanded === "panel7"}
              onChange={handleAccordionChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                aria-controls="panel7-content"
                id="panel7-header"
                className="faq-card"
              >
                <Typography className="accordion_text1">
                  Can viewers book tickets in advance?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion_text2">
                  Yes, viewers can book tickets up to 30 days in advance. This
                  allows you to promote your movie effectively and maximize your
                  opening collection.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ mt: 2 }}
              className="faq_card"
              expanded={expanded === "panel8"}
              onChange={handleAccordionChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                aria-controls="panel8-content"
                id="panel8-header"
                className="faq-card"
              >
                <Typography className="accordion_text1">
                  How can I track advance ticket sales?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion_text2">
                  You will be provided with your own dashboard to monitor the
                  progress of ticket sales for your movie.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ mt: 2 }}
              className="faq_card"
              expanded={expanded === "panel9"}
              onChange={handleAccordionChange("panel9")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                aria-controls="panel9-content"
                id="panel9-header"
                className="faq-card"
              >
                <Typography className="accordion_text1">
                  What are the financial arrangements, such as distribution
                  charges and profit sharing between us and CINET?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion_text2">
                  There are no distribution charges involved. Instead, profit
                  sharing is based on ticket sales. Typically, the producer's
                  share is 70%, and CINET takes 30%. The exact figures will be
                  outlined in the application form
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ mt: 2 }}
              className="faq_card"
              expanded={expanded === "panel10"}
              onChange={handleAccordionChange("panel10")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                aria-controls="panel10-content"
                id="panel10-header"
                className="faq-card"
              >
                <Typography className="accordion_text1">
                  When will I receive my share of the revenue?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion_text2">
                  After the scheduled period of one month, the revenue will be
                  calculated. Your share will be transferred to your bank
                  saccount within two weeks.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Typography className="accordion_text1">
              If you have any further questions, please don’t hesitate to
              contact us.
            </Typography>
            <Typography className="accordion_text1">
             We’re here to help.
            </Typography>
          </div>
          <div className="mt-3 mb-4">
            <Button
              variant="contained"
              className="faq-button"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us
            </Button>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={ContactImg}
              alt="Contact"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #101010 89.65%)",
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Faq;
