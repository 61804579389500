export const urlConfig = {
  // baseUrl: "https://apicinet.projectzerozilla.com", // Staging API URL
  baseUrl: "https://rpps2pk9sb.us-east-2.awsapprunner.com", // Prod API URL // EC2  https://api.cinetshow.com
  version: "v1", // Change this to your API version
  auth: {
    sendOtp: "/customer/auth/send-otp",
    verifyOtp: "/customer/auth/verify-otp",
    resendOtp: "/customer/auth/resend-otp",
    signup: "customer/auth/signup",
    google_login: "/customer/auth/login-with-google",
    switchtoproducer: "/customer/profile/switch-to-producer",

  },
  home: {
    homelist: "/customer/home/list",
    homeview: "/customer/home/view",
    deleteUser: "/user",
    player: "customer/home/get-vimeo-id",
    duration: "customer/home/duration/update",
    rate: "customer/home/movie-rating",
    searchmovie: "customer/home/search?",
    seeMore: "customer/home/see-more",
  },
  language: {
    list: "customer/language/list",
  },
  movieupload: {
    create: "/customer/movie/create",
    languagelist: "/customer/movie/language-list",
    generelist: "/customer/movie/genre-list",
    certificatelist: "/customer/movie/certificate-list",
    deletemovie: "/customer/movie/delete?id=",
  },
  payment: {
    createorderforRent: "/customer/home/create-order",
    rentMovie: "/customer/home/rent-movie",
    createorderforsubscription: "/customer/subscription/create-order",
    choosplan: "/customer/subscription/choose-plan",
    subscriptionList: "/customer/subscription/list?search=",
  },
  common: {
    imageupload: "/common/upload-image",
    videoupload: "/customer/movie/upload-video",
  },
  account: {
    updateProfile: "/customer/profile/update",
    getprofileDetailS: "/customer/profile/view",
    getWathHistory: "/customer/movie/watch-history/list",
    getTransactionHistory: "/customer/movie/transaction/list",
    getUploadedMovies: "/customer/movie/get-your-uploads",
    deleteUploadedMovie: "/customer/movie/delete",
  },
  introductionvideo: {
    getintroductionvideolanguage: "/customer/welcome-screen/list",
    getintroductionvideo: "/customer/welcome-screen/list?language_id=",
  },
  firstshow: {
    list: "/customer/home/list",
  },
  subscription: {
    list: "/customer/home/list",
  },
  song: {
    getVimeoId: "/customer/home/song/vimeo-id",
  },
  trailer: {
    getVimeoId: "/customer/home/trailer/vimeo-id",
  },
  wishlist: {
    toggle: "/customer/home/wishlist/add",
    list: "/customer/home/wishlist/list",
  },
  analytics: {
    getanalytics: "/customer/movie/get-analytics?movie_id=",
    getanalyticbydays: "/customer/movie/get-analytics?movie_id=",
    movetoSubscription: "/customer/movie/move-to-subscription",
  },
  contact: {
    submit: "/customer/contact/create",
  },
};
