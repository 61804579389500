import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { data } from "./BookingHistoryTableData";
// import buttonIcon from "../../images/AnalyticsPage/ButtonIcon.svg";
import { getanalytics } from "../../services/analytics";
import { useParams } from "react-router-dom";
import moment from "moment";

const BookingHistoryTable = () => {
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const { id } = useParams();
  const [bookinghistory, setbookinghistory] = useState([]);
  const [serachByname, setserachByname] = useState(null);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePrevious = () => {
    if (page > 1) {
      handlePageChange(page - 1);
    }
  };

  const handleNext = () => {
    if (page < Math.ceil(bookinghistory?.length / rowsPerPage)) {
      handlePageChange(page + 1);
    }
  };

  const fetchadata = async () => {
    try {
      const response = await getanalytics(id);
      if (response?.data?.data) {
        setbookinghistory(response?.data?.data?.booking_history);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchadataByname = async () => {
    try {
      const response = await getanalytics(
        `${parseInt(id)}&days=&search=${serachByname}`
      );
      if (response?.data?.data) {
        setbookinghistory(response?.data?.data?.booking_history);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchadata();
  }, []);
  useEffect(() => {
    if (serachByname === "" && serachByname !== null) {
      setserachByname(null);
      fetchadata();
    } else if (serachByname?.length > 2) {
      fetchadataByname();
    }
  }, [serachByname]);

  const handleSerach = (e) => {
    setserachByname(e.target.value);
  };
  return (
    <Container
      maxWidth={false}
      sx={{ color: "white", backgroundColor: "black" }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
        sx={{ padding: 2, alignItems: "center" }}
      >
        {/* <Box>
          <Button
            variant="contained"
            sx={{ marginRight: 2, backgroundColor: "#1D1E1E" }}
          >
            {" "}
            <span style={{ marginRight: "15px" }}>All Time</span> {"X"}
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#1D1E1E", whiteSpace: "nowrap" }}
          >
            {" "}
            <img src={buttonIcon} alt="" height="10px" width="15px" />{" "}
            <span style={{ marginLeft: "15px" }}>More Filters</span>
          </Button>
        </Box> */}
        <Box sx={{ padding: 2 }}>
          <Typography
            variant="h4"
            style={{
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "31.25px",
              textAlign: "left",
            }}
          >
            User Booking
          </Typography>
          <Typography
            variant="h6"
            style={{
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "23.44px",
            }}
          >
            Booking History
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" style={{ height: "44px" }}>
          <TextField
            variant="outlined"
            placeholder="Search"
            onChange={handleSerach}
            style={{
              background: "#1D1E1E",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "white" }} />
                </InputAdornment>
              ),
              sx: {
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  backgroundColor: "#1D1E1E",

                  borderRadius: 1,
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "white",
                  background: "#1D1E1E !important",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "white",
                },
              },
            }}
            sx={{ backgroundColor: "#1D1E1E", borderRadius: 1 }}
          />
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: "black",
          border: "2px solid #161616",
          borderRadius: "20px",
          margin: 2,
        }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#161616" }}>
              <TableCell sx={{ color: "white", borderBottom: "none" }}>
                User Info
              </TableCell>
              <TableCell sx={{ color: "white", borderBottom: "none" }}>
                Booked On
              </TableCell>
              <TableCell sx={{ color: "white", borderBottom: "none" }}>
                Email ID
              </TableCell>
              <TableCell sx={{ color: "white", borderBottom: "none" }}>
                Contact No.
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookinghistory?.length > 0 &&
              bookinghistory
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#1D1E1E" : "#161616",
                    }}
                  >
                    <TableCell
                      sx={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "none",
                      }}
                    >
                      <Avatar
                        src={row?.User?.profile_pic}
                        alt={row?.User?.name}
                        sx={{ marginRight: 2 }}
                      />
                      {row?.User?.name}
                    </TableCell>
                    <TableCell sx={{ color: "white", borderBottom: "none" }}>
                      {moment(row?.User?.createdAt).format("LL")}
                    </TableCell>
                    <TableCell sx={{ color: "white", borderBottom: "none" }}>
                      {row?.User?.email || 'N/A'}
                    </TableCell>
                    <TableCell sx={{ color: "white", borderBottom: "none" }}>
                      {row?.User?.mobile || 'N/A'}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
        sx={{ padding: 2 }}
      >
        <Box>
          <Button
            variant="contained"
            onClick={handlePrevious}
            disabled={page === 1}
            style={{
              backgroundColor: "#1D1E1E",
              color: page === 1 ? "rgba(255, 255, 255, 0.6)" : "white",
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{ marginLeft: 2 }}
            disabled={page === Math.ceil(bookinghistory?.length / rowsPerPage)}
            style={{
              backgroundColor: "#1D1E1E",
              color:
                page === Math.ceil(bookinghistory?.length / rowsPerPage)
                  ? "rgba(255, 255, 255, 0.6)"
                  : "white",
            }}
          >
            Next
          </Button>
        </Box>
        <Box sx={{ marginLeft: "auto", color: "white" }}>
          Page {page} of {Math.ceil(bookinghistory?.length / rowsPerPage)}
        </Box>
      </Box>
    </Container>
  );
};

export default BookingHistoryTable;
