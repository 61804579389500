import React, { useState, useEffect, useRef } from "react";
import OtpInput from "react-otp-input";
import Typography from "@mui/material/Typography";
import "../../scss/OtpPage.scss";
import Strings from "../../common/string";
import Button from "react-bootstrap/Button";
import Timer from "./Timer";
import { useNavigate, useLocation } from "react-router-dom";
import { verifyOtp, sendOtp } from "../../services/auth";
import { useDispatch } from "react-redux";
import { Backdrop, Link, CircularProgress } from "@mui/material";
import { getprofileDetailS } from "../../services/account";
import toast from "react-hot-toast";

const OtpPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mobileNumber } = location.state || {};
  const [otp, setOtp] = useState("");
  const [otpExpired, setOtpExpired] = useState(false);
  const [timerKey, setTimerKey] = useState(Date.now());
  const timerRef = useRef(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (timerRef.current) {
      timerRef.current.startTimer();
    }
  }, [timerKey]);

  const handleVerifyOtp = async () => {
    setLoading(true);
    const otp_token = localStorage.getItem("otp_token");
    if (otp && otp_token && !otpExpired) {
      try {
        const response = await dispatch(verifyOtp(otp, otp_token));
        if (response && response.data && response.data.data) {
          if (response.data.data.login_status === false) {
            localStorage.setItem("token", response?.data?.data?.access_token);
            // navigate("/account", {
            //   state: { mobileNumber: mobileNumber },
            //   login_status: response.data.data.login_status,
            // });
            navigate("/info", {
              state: { mobileNumber: mobileNumber },
              login_status: response.data.data.login_status,
            });
          } else {
            dispatch(getprofileDetailS());
            const userName = response.data.data.user
              ? response.data.data.user.name
              : response.data.data.producer.name;
            localStorage.setItem("user_name", userName);
            localStorage.setItem("token", response?.data?.data?.access_token);
            localStorage.setItem("role", response?.data?.data?.user?.role);
            localStorage.setItem(
              "profile",
              response?.data?.data.user.profile_pic
            );
            localStorage.setItem(
              "userData",
              JSON.stringify(response?.data?.data?.user)
            );
            localStorage.setItem("isAuthenticated", true);
            if (response?.data?.data?.user?.role === "producer") {
              navigate("/language", { replace: true });
            } else {
              navigate("/language", { replace: true });
            }
          }
        } else {
          throw new Error("Invalid response from server");
        }
      } catch (err) {
        setError(err.message);
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      alert("Invalid OTP or OTP expired");
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setOtp("");
    try {
      const numberWithoutCountryCode = mobileNumber;
      const response = await sendOtp(numberWithoutCountryCode);
      if (response && response.data && response.data.data.otp_token) {
        localStorage.setItem("otp_token", response?.data?.data?.otp_token);
        localStorage.setItem(
          "otpTime",
          Math.floor(Date.now() / 1000).toString()
        );
        setOtp("");
        setOtpExpired(false);
        setTimerKey(Date.now());
      } else {
        throw new Error("Failed to resend OTP");
      }
    } catch (err) {
      setError(err.message);
      console.error("Error resending OTP:", err);
    }
  };

  const handleTimeout = () => {
    setOtpExpired(true);
  };

  const handleback = () => {
    navigate("/login");
  };

  const renderInput = (inputProps) => (
    <input {...inputProps} className="otp-input" />
  );

  const handleChange = (value) => {
    // Check if the value consists only of digits
    if (/^\d*$/.test(value)) {
      setOtp(value);
    }
  };
  return (
    <div className="full-page-background-otp">
      <div className="container">
        <div className="background-otp">
          <div className="cardvalue-otp">
            <Button
              className="skip-otp"
              variant="secondary"
              onClick={handleback}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M15 18.5L9 12.5L15 6.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="login-Header-otp"
            >
              {Strings.FOUR_DIGIT_CODE}
            </Typography>
            <p
              id="modal-modal-title"
              component="h2"
              style={{
                color: "#E6E6E6",
                display: "flex",
                justifyContent: "center",
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              {Strings.SORT_TEXT}
            </p>
            <div className="otp">
              <OtpInput
                key={timerKey}
                value={otp}
                onChange={handleChange}
                numInputs={4}
                separator={<span>-</span>}
                renderInput={renderInput}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              />
            </div>
            <div className="Resend-otp mt-2 mb-2">
              {!otpExpired ? (
                <p id="modal-modal-title" component="h2">
                  Didn't receive a code?{" "}
                  <Link
                    style={{
                      color: "gray",
                      marginLeft: "10px",
                      textDecoration: "none",
                    }}
                  >
                    Resend OTP
                  </Link>
                </p>
              ) : (
                <p id="modal-modal-title" component="h2">
                  Didn’t receive a code?{" "}
                  <Link
                    style={{
                      color: "#f1e809",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    onClick={handleResendOtp}
                  >
                    Resend OTP
                  </Link>
                </p>
              )}
              <Timer
                ref={timerRef}
                key={timerKey}
                initialTime={60}
                onTimeout={handleTimeout}
              />
            </div>
            <Button
              className="Continue-Button-otp"
              variant="secondary"
              onClick={handleVerifyOtp}
            >
              {Strings.VERIFY_OTP}
            </Button>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>
        </div>
      </div>
      <Backdrop
        open={loading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default OtpPage;
