import { FileUploadService, ApiService } from "./ApiService/ApiService";
import { urlConfig } from "../config/urlConfig";

export const uploadImageApi = (data) => {
  return FileUploadService.post(urlConfig?.common?.imageupload, data);
};
export const uploadVideoApi = (data) => {
  
  return FileUploadService.post(urlConfig?.common?.videoupload, data);
};
export const movieCreateApi = (data) => {
  return ApiService.post(urlConfig?.movieupload?.create, data);
};

export const getintroductionvideolanguage = (data) => {
  return ApiService.get(
    urlConfig?.introductionvideo?.getintroductionvideolanguage
  );
};

export const getintroductionvideo = (id) => {
  return ApiService.get(
    `${urlConfig?.introductionvideo.getintroductionvideo}${id}`
  );
};

export const getGenreList = (id) => {
  return ApiService.get(`${urlConfig?.movieupload.generelist}`);
};

export const getlanguageList = (id) => {
  return ApiService.get(`${urlConfig?.movieupload.languagelist}`);
};
export const getcertificatelist = (id) => {
  return ApiService.get(`${urlConfig?.movieupload.certificatelist}`);
};

export const deleteMovie = (id) => {
  return ApiService.delete(`${urlConfig?.movieupload.deletemovie}${id}`);
};

export const getSearchFilter = (id, search, page) => {
  return ApiService.get(
    `${urlConfig?.home.searchmovie}language_id=${
      id !== null || id !== undefined ? id : ""
    }&search=${search}&page=${page}&page_size=10`
  );
};
