import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
// import { toast } from "react-hot-toast";
// import { uploadImageApi } from "../../services/movieupload";
// import { signup } from "../../services/auth";
// import { getprofileDetailS } from "../../services/account";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import "../../scss/Producerdetails.scss";

const ProducerUpdate = () => {
  // const location = useLocation();
  // const { mobileNumber, data } = location.state || {};
  const [loading, setLoading] = useState(false);
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [files, setfiles] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const [formState, setFormState] = useState({
    ...user,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    // const { name, value, type, files, checked } = e.target;
    // const newValue = type === "checkbox" ? checked : files ? files[0] : value;
    // setFormState((prevState) => {
    // const updatedState = { ...prevState, [name]: newValue };
    // validateField(name, newValue);
    // return updatedState;
    // });
  };

  const handleFileChange = async (event) => {
    // const { name } = event.target;
    // const file = event.target.files[0];
    // const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
    // if (!allowedTypes.includes(file.type)) {
    //   toast.error(
    //     "Invalid file type. Only .png, .jpg, .jpeg, and .pdf are allowed."
    //   );
    //   return;
    // }
    // setfiles({
    //   ...files,
    //   [name]: file,
    // });
    // setLoading(true);
    // if (file) {
    //   try {
    //     const formData = new FormData();
    //     formData.append("file", file);
    //     const response = await uploadImageApi(formData);
    //     if (response && response.data) {
    //       setFormState((prevState) => ({
    //         ...prevState,
    //         [name]: response.data.data?.filePath,
    //       }));
    //       toast.success("Image uploaded successfully.");
    //     } else {
    //       toast.error("Failed to upload image. Please try again.");
    //     }
    //   } catch (error) {
    //     toast.error("An error occurred while uploading the image.");
    //   } finally {
    //     setLoading(false);
    //   }
    // }
  };

  console.log("formState", formState);

  useEffect(() => {
    if (user) {
      setFormState(() => ({
        pan_number: user?.pan_number,
        id_proof_url: user?.id_proof_url,
        gst_number: user?.gst_number,
        gst_certificate_url: user?.gst_certificate_url,
        cancelled_cheque_url: user?.cancelled_cheque_url,
        bank_account_number: user?.bank_account_number,
        bank_name: user?.bank_name,
        account_holder_name: user?.account_holder_name,
        ifsc_code: user?.ifsc_code,
        terms_and_condition: user?.terms_and_condition,
        production_house_name: user?.production_house_name,
      }));
    }
  }, [user]);

  return (
    <>
      <div className="my-4">
        <Typography variant="h5" component="div" gutterBottom>
          Producer Details
        </Typography>
        <Typography variant="subtitle1" component="div" gutterBottom>
          Update Your Producer Details Information
        </Typography>
      </div>
      <div className="profile-form">
        <Form>
          <Row
            style={{
              marginLeft: "1rem",
            }}
          >
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Production House</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Enter Production House Name"
                name="production_house_name"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.production_house_name}
                value={formState.production_house_name || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.production_house_name}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">GST Number</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Enter GST Number"
                name="gst_number"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.gst_number}
                value={formState.gst_number || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.gst_number}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Pan Card</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Enter PAN Number"
                name="pan_number"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.pan_number}
                value={formState.pan_number || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pan_number}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                ID Proof
                <span>(500KB)</span>
              </Form.Label>
              <Form.Group>
                <a
                  href={formState?.id_proof_url || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="form-control-1"
                  style={{
                    display: "block",
                    padding: "0.375rem 0.75rem",
                    border: "1px solid #ced4da",
                    borderRadius: "0.25rem",
                    textDecoration: "none",
                    color: "#495057",
                  }}
                >
                  <p className="mt-1">
                    {" "}
                    {formState?.id_proof_url
                      ? "View ID Proof"
                      : "No ID Proof Provided"}{" "}
                  </p>
                </a>
                {errors.id_proof_url && (
                  <Form.Control.Feedback type="invalid">
                    {errors.id_proof_url}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              {/* <div
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <Form.Control
                  disabled
                  type="file"
                  className="file-input-producer"
                  name="id_proof_url"
                  id="file-upload"
                  onChange={handleFileChange}
                  readOnly
                  isInvalid={!!errors.id_proof_url}
                  style={{
                    padding: "1rem",
                  }}
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius: "0",
                  }}
                  variant="primary"
                  onClick={() => document.getElementById("file-upload").click()}
                  className="input-group-append"
                >
                  Upload
                </Button>
              </div> */}
            </Col>
            {/* <Col lg={4} md={6} sm={12}> */}
            {/* <Form.Label className="my-2">
                Cbf Certificate
                <span> (500KB)</span>
              </Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Enter Cbf Certificate"
                name="cbfc_certificate"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.cbfc_certificate}
                value={formState.cbfc_certificate || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cbfc_certificate}
              </Form.Control.Feedback> */}
            {/* <div
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <Form.Control
                  disabled
                  type="file"
                  className="file-input-producer"
                  name="cbfc_certificate"
                  id="file-upload"
                  onChange={handleFileChange}
                  readOnly
                  isInvalid={!!errors.id_proof_url}
                  style={{
                    padding: "1rem",
                  }}
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius: "0",
                  }}
                  variant="primary"
                  onClick={() => document.getElementById("file-upload").click()}
                  className="input-group-append"
                >
                  Upload
                </Button>
              </div> */}
            {/* </Col> */}
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                Cancelled Cheque <span>(500KB)</span>
              </Form.Label>

              <Form.Group>
                <a
                  href={formState?.cancelled_cheque_url || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="form-control-1"
                  style={{
                    display: "block",
                    padding: "0.375rem 0.75rem",
                    border: "1px solid #ced4da",
                    borderRadius: "0.25rem",
                    textDecoration: "none",
                    color: "#495057",
                  }}
                >
                  <p className="mt-1">
                    {" "}
                    {formState?.cancelled_cheque_url
                      ? "View Cancelled Cheque"
                      : "No Cancelled Cheque Provided"}{" "}
                  </p>
                </a>
                {errors.cancelled_cheque_url && (
                  <Form.Control.Feedback type="invalid">
                    {errors.cancelled_cheque_url}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              {/* <div
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <Form.Control
                  disabled
                  type="file"
                  className="file-input-producer"
                  name="cancelled_cheque_url"
                  onChange={handleFileChange}
                  isInvalid={!!errors.cancelled_cheque_url}
                  style={{
                    padding: "1rem",
                  }}
                  accept=".jpg,.jpeg,.png,.pdf"
                  readOnly
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius: "0",
                  }}
                  className="input-group-append"
                  variant="primary"
                  // accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                  onClick={() =>
                    document
                      .querySelector('input[name="cancelled_cheque_url"]')
                      .click()
                  }
                >
                  Upload
                </Button>
              </div> */}
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                GST Certificate
                <span>(500KB)</span>
              </Form.Label>
              <Form.Group>
                <a
                  href={formState?.gst_certificate_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="form-control-1"
                  style={{
                    display: "block",
                    padding: "0.375rem 0.75rem",
                    border: "1px solid #ced4da",
                    borderRadius: "0.25rem",
                    textDecoration: "none",
                    color: "#495057",
                  }}
                >
                  <p className="mt-1">
                    {" "}
                    {formState?.gst_certificate_url
                      ? "View GST Certificate"
                      : "No GST Certificate Provided"}{" "}
                  </p>
                </a>
                {errors.gst_certificate_url && (
                  <Form.Control.Feedback type="invalid">
                    {errors.gst_certificate_url}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              {/* <div
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <Form.Control
                  disabled
                  type="file"
                  className="file-input-producer"
                  name="gst_certificate_url"
                  onChange={handleFileChange}
                  isInvalid={!!errors.gst_certificate_url}
                  style={{
                    padding: "1rem",
                  }}
                  readOnly
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius: "0",
                  }}
                  className="input-group-append"
                  variant="primary"
                  onClick={() =>
                    document
                      .querySelector('input[name="gst_certificate_url"]')
                      .click()
                  }
                >
                  Upload
                </Button>
              </div> */}
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Bank Account Number</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Enter Bank Account Number"
                name="bank_account_number"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.bank_account_number}
                value={formState.bank_account_number || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bank_account_number}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Bank Name</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Enter Bank Name"
                name="bank_name"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.bank_name}
                value={formState.bank_name || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bank_name}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">IFSC Code</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Enter IFSC Code"
                name="ifsc_code"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.ifsc_code}
                value={formState.ifsc_code || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ifsc_code}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Account Holder's Name</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Enter Account Holder's Name"
                name="account_holder_name"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.account_holder_name}
                value={formState.account_holder_name || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.account_holder_name}
              </Form.Control.Feedback>
            </Col>
            <Col lg={12} md={12} sm={12} className="my-1">
              <Form.Check
                type="checkbox"
                label="I confirm the terms and conditions"
                name="terms_and_condition"
                onChange={handleChange}
                className="form-check-1"
                isInvalid={!!errors.terms_and_condition}
                checked={formState.terms_and_condition || false}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                {errors.terms_and_condition}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Button
            type="submit"
            variant="primary"
            className="mt-3"
            style={{
              background: "#fed530",
              border: "1px solid #fed530",
              color: "black",
              marginLeft: "2rem",
            }}
            disabled
          >
            Save
          </Button>
        </Form>
        <Backdrop
          open={loading}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};

export default ProducerUpdate;
