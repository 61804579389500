import React, { useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import Strings from "../../common/string";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import uploadIcon from "../../images/welcome/upload.svg";
import { signup } from "../../services/auth";
import { Cropper, ImageRestriction } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import "../../scss/PersonalInfo.scss";
import { toast } from "react-toastify";
import { uploadImageApi } from "../../services/movieupload";

export default function PersonalInformation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { mobileNumber, selectedCard } = location.state || {};
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const cropperRef = useRef(null);
  const [formError, setformError] = useState({});
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setIsCropping(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = async (blob) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", blob, "croppedImage.png");
      const uploadResponse = await uploadImageApi(formData);
      if (uploadResponse.status === 200) {
        const imageUrl = uploadResponse.data.data.filePath;
        setUploadedImageUrl(imageUrl);
        toast.success("Image uploaded successfully.");
      } else {
        toast.error("Failed to upload image. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred while uploading the image.");
    } finally {
      setLoading(false);
    }
  };

  const handleCrop = async () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.getCanvas();
      croppedCanvas.toBlob(async (blob) => {
        await handleImageUpload(blob);
        const croppedImageUrl = croppedCanvas.toDataURL();
        setCroppedImage(croppedImageUrl);
        setIsCropping(false);
      });
    }
  };

  const handleContinue = async () => {
    const errors = validation(name, email);
    if (Object.keys(errors).length > 0) {
      setformError(errors);
      return;
    }

    setLoading(true);
    // if (selectedCard === "user") {
    try {
      const accessToken = localStorage.getItem("token");
      const payload = {
        name,
        email,
        mobile: mobileNumber,
        profile_pic:
          uploadedImageUrl || null, // "https://s3.amazonaws.com/bucket/path/image.png"
        role: "user",
        google_id: "",
        doc_url: "",
        access_token: accessToken,
      };

      const response = await signup(payload);
      if (response?.data?.status && response.data) {
        if (response.data?.data?.user) {
          localStorage.setItem("user_name", response.data?.data?.user?.name);
          localStorage.setItem("token", response.data?.data?.access_token);
          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem(
            "userData",
            JSON.stringify(response?.data?.data?.user)
          );
          if (response?.data?.data?.user?.role === "producer") {
            navigate("/producer-details", {
              state: { data: response.data?.data },
            });
          } else {
            navigate("/language");
          }
        } else {
          localStorage.setItem(
            "user_name",
            response.data?.data?.producer?.name
          );
          navigate("/welcome");
        }
      } else {
        throw new Error("Failed to submit personal info");
      }
    } catch (err) {
      setError(err.message);
      console.error("Error submitting personal info:", err);
    } finally {
      setLoading(false);
    }
  };

  const validation = (name, email) => {
    const errors = {};
    if (!name) {
      errors.name = "Name is required";
    }
    if (!email) {
      errors.email = "Email is required";
    }
    return errors;
  };

  return (
    <div className="full-page-background-login">
      {isCropping && (
        <div className="modal-overlay">
          <div className="cropper-container">
            <Cropper
              ref={cropperRef}
              src={image}
              style={{ width: "100%", height: "300px" }}
              imageRestriction={ImageRestriction.fillArea}
              className="cropper-circular"
            />
            <Button onClick={handleCrop}>Crop Image</Button>
          </div>
        </div>
      )}
      <div className="container">
        <div className="background">
          <div className="cardvalue">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="info-Header"
            >
              {Strings.PERSONALINFO}
            </Typography>
            <p
              id="modal-modal-title"
              component="h2"
              style={{
                // color: "#fff",
                display: "flex",
                justifyContent: "right",
                fontFamily: "DM Sans",
                fontWeight: "400",
                fontSize: "16px",
                color: " #E6E6E6",
              }}
            >
              Note: Document accept only JPG, PNG file format and should be less
              than 10MB.
            </p>
            <div className="mt-4 upload-container">
              <div className="upload-placeholder">
                {croppedImage ? (
                  <img src={croppedImage} alt="Cropped" />
                ) : (
                  <img src={uploadIcon} alt="img" width="80px" height="80px" />
                )}
              </div>
              <span
                className="upload-text"
                onClick={() => document.getElementById("fileInput").click()}
              >
                Upload Display Picture
              </span>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>
            <div className="namefield">
              <Form.Label className="mobNum">Name*</Form.Label>
              <Form.Control
                className="nameinput"
                type="text"
                placeholder="Enter Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  textAlign: "start",
                  background: "#2E2E2E",
                  border: "1px solid #2E2E2E",
                  color: "#FFF",
                  fontFamily: "DM Sans",
                  fontWeight: "500",
                  fontSize: "16px",
                  borderRadius: "0 !important",
                }}
                isInvalid={!!formError.name}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.name}
              </Form.Control.Feedback>
            </div>
            <div className="namefield my-4">
              <Form.Label className="mobNum">Email*</Form.Label>
              <Form.Control
                className="nameinput"
                type="email"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!formError.email}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.email}
              </Form.Control.Feedback>
            </div>
            <Button
              className="Continue-Button-login mt-4"
              variant="secondary"
              onClick={handleContinue}
              disabled={loading}
              style={{
                display: "flex",
                justifyContent: "center",
                fontFamily: "DM Sans",
                fontWeight: "500",
                fontSize: "20px",
                paddingTop: "10px",
              }}
            >
              {Strings.CONTINUE_BUTTON}
            </Button>
            {error && <p style={{ color: "red" }}>{error}</p>}{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
