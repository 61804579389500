// import React, { useState } from "react";
// import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
// import { Grid, Typography, FormControl, Button } from "@mui/material";
// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import moment from "moment/moment";

// const ResponsiveDatePickers = ({ onChange }) => {
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [selectedTime, setSelectedTime] = useState(null);

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//     const formattedDate = moment(date).format("YYYY-MM-DD");
//     onChange("schedule_date", formattedDate);
//   };

//   const handleTimeChange = (time) => {
//     setSelectedTime(time);
//     const formattedTime = time
//       ? time.toLocaleTimeString([], {
//         hour: "2-digit",
//         minute: "2-digit",
//         hour12: false,
//       })
//       : "";
//     onChange("schedule_time", formattedTime);
//   };

//   const clearDateTime = () => {
//     setSelectedDate(null);
//     setSelectedTime(null);
//     onChange("schedule_date", "");
//     onChange("schedule_time", "");
//   };

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={5}>
//         <Typography variant="subtitle1" style={{ color: "#fff" }}>
//           Date
//         </Typography>
        
//         <FormControl fullWidth variant="outlined">
//           <DatePicker
//             selected={selectedDate}
//             onChange={handleDateChange}
//             dateFormat="MMMM d, yyyy"
//             minDate={new Date()}
//             customInput={
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   backgroundColor: "#000",
//                   borderRadius: "10px", // Added rounded corners
//                   padding: "8px",
//                   border: "2px solid gray", // Added border
//                   marginBottom: "20px", // Added margin-bottom
//                 }}
//               >
//                 <CalendarTodayIcon
//                   style={{ color: "#fff", marginRight: "8px" }}
//                 />
//                 <input
//                   type="text"
//                   readOnly
//                   placeholder="Select Date"
//                   value={selectedDate ? selectedDate.toLocaleDateString() : ""}
//                   style={{
//                     flex: 1,
//                     border: "none",
//                     background: "black", // Input background color
//                     color: "white", // Input text color
//                     outline: "none",
//                     textAlign: "left",
//                     height: "45px",
//                     padding: "8px", // Padding inside the input
//                     borderRadius: "10px", // Added rounded corners to the input
//                   }}
//                 />
//               </div>
//             }
//           />
//         </FormControl>

//       </Grid>
//       <Grid item xs={5}>
//         <Typography variant="subtitle1" style={{ color: "#fff" }}>
//           Time
//         </Typography>
      
//         <FormControl fullWidth variant="outlined">
//           <DatePicker
//             selected={selectedTime}
//             onChange={handleTimeChange}
//             showTimeSelect
//             showTimeSelectOnly
//             timeIntervals={5}
//             timeCaption="Time"
//             dateFormat="h:mm aa"
//             customInput={
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   backgroundColor: "black", // Input container background
//                   borderRadius: "10px", // Rounded corners
//                   padding: "8px",
//                   border: "2px solid gray", // Border styling
//                   marginBottom: "20px", // Spacing below
//                 }}
//               >
//                 <AccessTimeIcon style={{ color: "#fff", marginRight: "8px" }} />
//                 <input
//                   type="text"
//                   placeholder="Select Time"
//                   readOnly
//                   value={
//                     selectedTime
//                       ? selectedTime.toLocaleTimeString([], {
//                         hour: "2-digit",
//                         minute: "2-digit",
//                       })
//                       : ""
//                   }
//                   style={{
//                     flex: 1,
//                     border: "none",
//                     background: "black", // Background color of the input
//                     color: "white", // Text color inside the input
//                     outline: "none",
//                     textAlign: "left",
//                     height: "45px",
//                     borderRadius: "10px", // Rounded corners for input
//                   }}
//                 />
//               </div>
//             }
//           />
//         </FormControl>

//       </Grid>
//       <Grid item xs={2}>
//         <Button
//           variant="contained"
//           color="secondary"
//           onClick={clearDateTime}
//           sx={{
//             backgroundColor: "rgb(254, 213, 48)",
//             borderRadius: '10px',
//             color: "#000",
//             fontWeight: '550',
//             fontFamily: "sans-serif",
//             fontSize: '16px',
//             padding: '16px',
//             mt: 4,
//             "&:hover": {
//               backgroundColor: "rgb(255, 235, 59)",
//             },
//           }}
//         >
//           Clear Date & Time
//         </Button>
//       </Grid>
//       <Grid item xs={12}>
//         <div style={{ color: "#fff" }}>
//           <p style={{ textAlign: "left" }}>
//             <span>
//               {selectedDate
//                 ? ` ${moment(selectedDate).format("ddd, D MMM")}, `
//                 : ""}
//             </span>{" "}
//             <span>
//               {" "}
//               {selectedTime
//                 ? `${moment(selectedTime).format("hh:mm A")}, `
//                 : ""}
//             </span>{" "}
//             India Standard Time, based on your location
//           </p>
//         </div>
//       </Grid>
//     </Grid>
//   );
// };

// export default ResponsiveDatePickers;
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Grid, Typography, FormControl, Button } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment/moment";

const ResponsiveDatePickers = ({ onChange }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    onChange("schedule_date", formattedDate);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    const formattedTime = time
      ? time.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
      : "";
    onChange("schedule_time", formattedTime);
  };

  const clearDateTime = () => {
    setSelectedDate(null);
    setSelectedTime(null);
    onChange("schedule_date", "");
    onChange("schedule_time", "");
  };

  // Determine if the selected date is today
  const isToday = selectedDate ? moment(selectedDate).isSame(new Date(), "day") : false;

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Typography variant="subtitle1" style={{ color: "#fff" }}>
          Date
        </Typography>

        <FormControl fullWidth variant="outlined">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MMMM d, yyyy"
            minDate={new Date()}
            customInput={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#000",
                  borderRadius: "10px",
                  padding: "8px",
                  border: "2px solid gray",
                  marginBottom: "20px",
                }}
              >
                <CalendarTodayIcon style={{ color: "#fff", marginRight: "8px" }} />
                <input
                  type="text"
                  readOnly
                  placeholder="Select Date"
                  value={selectedDate ? selectedDate.toLocaleDateString() : ""}
                  style={{
                    flex: 1,
                    border: "none",
                    background: "black",
                    color: "white",
                    outline: "none",
                    textAlign: "left",
                    height: "45px",
                    padding: "8px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="subtitle1" style={{ color: "#fff" }}>
          Time
        </Typography>

        <FormControl fullWidth variant="outlined">
          <DatePicker
            selected={selectedTime}
            onChange={handleTimeChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption="Time"
            dateFormat="h:mm aa"
            // Conditionally set minTime and maxTime based on whether the selected date is today
            {...(isToday && {
              minTime: new Date(),
              maxTime: new Date().setHours(23, 59),
            })}
            customInput={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "black",
                  borderRadius: "10px",
                  padding: "8px",
                  border: "2px solid gray",
                  marginBottom: "20px",
                }}
              >
                <AccessTimeIcon style={{ color: "#fff", marginRight: "8px" }} />
                <input
                  type="text"
                  placeholder="Select Time"
                  readOnly
                  value={
                    selectedTime
                      ? selectedTime.toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : ""
                  }
                  style={{
                    flex: 1,
                    border: "none",
                    background: "black",
                    color: "white",
                    outline: "none",
                    textAlign: "left",
                    height: "45px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={clearDateTime}
          sx={{
            backgroundColor: "rgb(254, 213, 48)",
            borderRadius: "10px",
            color: "#000",
            fontWeight: "550",
            fontFamily: "sans-serif",
            fontSize: "16px",
            padding: "16px",
            mt: 4,
            "&:hover": {
              backgroundColor: "rgb(255, 235, 59)",
            },
          }}
        >
          Clear Date & Time
        </Button>
      </Grid>
      <Grid item xs={12}>
        <div style={{ color: "#fff" }}>
          <p style={{ textAlign: "left" }}>
            <span>{selectedDate ? ` ${moment(selectedDate).format("ddd, D MMM")}, ` : ""}</span>{" "}
            <span>{selectedTime ? `${moment(selectedTime).format("hh:mm A")}, ` : ""}</span>{" "}
            India Standard Time, based on your location
          </p>
        </div>
      </Grid>
    </Grid>
  );
};

export default ResponsiveDatePickers;


