import React, { useEffect } from "react";

import "../../scss/MovieConstruction.scss";
import Button from "react-bootstrap/Button";
import ContactImg from "../../images/Contact/contact_img.jpeg";
import Oops from "../../images/Contact/oops.png";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import Footer from "../../common/footer/Footer";

const MoviesConstruction = ({
  navigateTo = "home",
  showLanguageText = true,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = () => {
    navigate(`/${navigateTo}`);
  };

  // Check if the current page is the home page
  const isHomePage = location.pathname === "/" || location.pathname === "/home";

  // useEffect(() => {
  //   // Disable page scroll
  //   document.body.style.overflow = "hidden";

  //   // Cleanup function to reset the overflow when the component is unmounted
  //   return () => {
  //     document.body.style.overflow = "";
  //   };
  // }, []);

  return (
    <Grid container className="contact-body">
      <Grid container>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            py: { xs: "1rem", md: 7 },
            px: { xs: "1rem", md: 10 },
          }}
        >
          <div className="empty-state">
            <div className="empty-state-content">
              <img
                src={Oops}
                alt="Oops"
                style={{ height: "80px", width: "75px" }}
              />
              <h2>Oops! We couldn't find any matches</h2>
              {showLanguageText && (
                <p>
                  We didn’t find that movie. Want to explore the latest releases
                  instead?.
                </p>
              )}
              {!isHomePage && (
                <Button
                  className="Continue-Button-intropage"
                  variant="secondary"
                  onClick={handleClick}
                >
                  Go Back Home
                </Button>
              )}
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={ContactImg}
              alt="Contact"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #101010 89.65%)",
              }}
            />
          </div>
        </Grid>
      </Grid>
      {isHomePage && <Footer />}
    </Grid>
  );
};

export default MoviesConstruction;
