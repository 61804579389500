// import { Container } from "@mui/material";
// import React, { useEffect } from "react";
// import "../../scss/PrivacyPolicy.scss";

// const PrivacyPolicy = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <>
//       <div className="terms-container">
//         <Container>
//           <div className="terms-header">Privacy Policy</div>
//           <div className="terms-content">
//             <div className="terms-header">Introduction</div>
//             <div className="terms-header">Who Are We and What Do We Do? </div>
//             <div className="terms-section">
//               This Privacy Policy outlines the practices of FLIXADDA LLP
//               (collectively, "CiNet," "we," "us," or "our"). We provide online
//               on-demand films, videos, audios, and video/audio hosting, sharing,
//               and related services through our owned-and-operated websites,
//               including CiNet.com, as well as our branded applications for
//               mobile and connected devices (collectively, the "services").{" "}
//             </div>
//             <div className="terms-header">Acceptance and Changes </div>
//             <div className="terms-section">
//               By registering for, downloading, or using our services, you accept
//               this Privacy Policy, which is a part of our Terms of Service. We
//               may update this Privacy Policy sometimes. Any modifications will
//               be posted on our website. If any changes significantly reduce our
//               commitments to you, we will notify registered users by email or
//               other methods.
//             </div>
//             <div className="terms-header">Children's Privacy </div>
//             <div className="terms-section">
//               We do not knowingly collect personal information from individuals
//               under the minimum required ages specified herein. You must be at
//               least 18 years old or the age of majority in your jurisdiction,
//               whichever is greater, to use our services. Individuals under the
//               applicable age may only use our services through a parent or legal
//               guardian's account with their involvement. If you are a parent or
//               legal guardian who believes your child has provided personal
//               information to CiNet without your consent, you may request its
//               deletion by contacting us at info@cinetshow.com
//             </div>
//             <div className="terms-header"> Data We Collect About You </div>
//             <div className="terms-section">
//               We collect information about you when you use our services, and
//               third parties may also collect information when you use our
//               services. This data may include personal information that
//               identifies you and non-personal information. We refer to your
//               information as "your data."
//             </div>
//             <div className="terms-header"> Account Information </div>
//             <div className="terms-section">
//               To create an account, you must provide a valid email address and
//               password. If you choose to sign up and/or authenticate using a
//               third-party account (e.g.,Google), you authorize us to obtain
//               account information from the third-party platform.
//             </div>
//             <div className="terms-header"> Financial Information </div>
//             <div className="terms-section">
//               To make a purchase, you need to use your CiNet E-Wallet, governed
//               by our E-Wallet Terms and Conditions. Your payment information
//               will be collected and processed by our authorized payment vendors.
//               We do not directly collect or store credit or debit card numbers
//               ourselves during transaction processing. If a purchase is made
//               through a third-party platform (i.e., in-app purchase), the
//               payment method stored with that platform will be charged.
//             </div>
//             <div className="terms-header"> Content </div>
//             <div className="terms-section">
//               Account holders may create an account and upload content such as
//               videos, text, photographs, and artwork. Your profile may display
//               information about you and your activities, which others can
//               access. Your videos may be viewed and accessed by others, and
//               associated metadata (e.g., titles, descriptions, tags, etc.) will
//               be visible to others. Your interactions with other users (e.g.,
//               comments, "likes," private messages) may be visible to others. You
//               may choose to limit the availability of your profile and videos.
//               For more details, see Section 9.{" "}
//             </div>
//             <div className="terms-header">
//               {" "}
//               Other Information You May Submit{" "}
//             </div>
//             <div className="terms-section">
//               You may submit data to us for limited purposes such as requesting
//               customer support, answering a questionnaire, participating in a
//               study or survey, entering contests or sweepstakes, or signing up
//               to receive communications from us or another user.
//             </div>
//             <div className="terms-header">
//               Automatically Collected Information
//             </div>
//             <div className="terms-section">
//               We automatically collect certain types of data when you use our
//               services, regardless of whether you have an account. This data
//               includes your IP address, technical information about your device
//               (e.g., browser type and version, operating system, basic device
//               information, geographical location, referral source, length of
//               visit, page views, website navigation), the web page you visited
//               or search query you entered before reaching us, and your
//               activities. We may track your activities using cookies and similar
//               technologies. By using our services, you agree to our use of these
//               methods as outlined in our Cookie Policy.
//             </div>
//             <div className="terms-header">Physical Products </div>
//             <div className="terms-section">
//               We collect your shipping address to send you products you have
//               ordered.
//             </div>
//             <div className="terms-header">
//               Information Collected by Third Parties{" "}
//             </div>
//             <div className="terms-section">
//               Some third parties may collect data about you when you use our
//               services. This may include data you submit (such as payment
//               information) or automatically collected information (in the case
//               of third-party analytics providers and advertising partners).
//             </div>
//             <div className="terms-section">
//               We may obtain data from third parties about you and combine it
//               with the information we have collected. For example, some
//               advertisers or advertising platforms may allow us to determine
//               what other online services you might use so that we may place
//               relevant ads on those services.
//             </div>
//             <div className="terms-header">How We Use Your Data </div>
//             <div className="terms-section">
//               We may use your data for the following purposes : &nbsp;
//             </div>
//             <div className="terms-section">
//               <b>Identification and Authentication : &nbsp;</b> We use your data
//               to verify you when you access your account.
//             </div>
//             <div className="terms-section">
//               <b>Operating Our Services : &nbsp;</b>
//               We use your data to provide our services, process and fulfill
//               orders, provide customer support, and comply with our contractual
//               obligations to you. We (and/or our third-party vendors) use your
//               financial information to process purchases made by you and to pay
//               you amounts you have earned.{" "}
//             </div>
//             <div className="terms-section">
//               <b>Communicating with You : &nbsp;</b>
//               We use your data when we communicate with you (e.g., responding to
//               a customer support inquiry).
//             </div>
//             <div className="terms-section">
//               <b>Improving Our Services : &nbsp;</b>
//               We use your data to understand how our services are being used and
//               how we can improve them. Generally, we analyze aggregated data
//               rather than specific user data. However, we may need to analyze a
//               specific case to address a specific problem (e.g., a bug affecting
//               a few accounts).
//             </div>
//             <div className="terms-section">
//               <b>Customizing Your Experience : &nbsp;</b>
//               We use your data to personalize the service for you. This may
//               include remembering your preferences for language or volume or
//               displaying videos that you might enjoy based on your viewing
//               choices.
//             </div>
//             <div className="terms-section">
//               <b>Marketing and Advertising : &nbsp;</b>
//               We use your data to show you ads on third-party sites and deliver
//               third-party advertisements to you. This may include "targeted ads"
//               based on your activities.
//             </div>
//             <div className="terms-section">
//               <b>Exercising Our Rights : &nbsp;</b>
//               Where reasonably necessary, we use your data to exercise our legal
//               rights and prevent abuse of our service. For example, we may use
//               your data to detect and prevent fraud, spam, or content that
//               violates our Terms of Service.
//             </div>
//             <div className="terms-section">
//               <b>Legal Compliance : &nbsp;</b>
//               We use your data where we are legally required to do so. For
//               example, we may need to gather your data to respond to a subpoena
//               or court order.
//             </div>{" "}
//             <div className="terms-section">
//               <b>Protecting Your Information : &nbsp;</b>
//               Where appropriate, we may anonymize, back up, and delete certain
//               data.
//             </div>{" "}
//             <div className="terms-section">
//               We may use algorithms and other automated means to implement any
//               of the above.
//             </div>
//             <div className="terms-section">
//               Whenever we process your data for one of these purposes, we have
//               determined that one or more of the following lawful bases apply :
//               &nbsp;
//             </div>
//             <div className="terms-section">
//               <ul>
//                 <li>Performance of a Contract</li>
//                 <li>Legitimate Commercial Interest</li>
//                 <li>Compliance with a Legal Obligation</li>
//                 <li>Consent</li>
//               </ul>
//             </div>
//             <div className="terms-section">
//               If you require further information about the balancing test that
//               CiNet has undertaken to justify its reliance on legitimate
//               interest as a lawful basis of processing, see Section 15 for
//               information on how to contact us.
//             </div>
//             <div className="terms-header">With Whom We Share Your Data</div>
//             <div className="terms-section">
//               We share data with third parties as follows : &nbsp;
//             </div>{" "}
//             <div className="terms-section">
//               <b> As You Instruct : &nbsp;</b>
//               We may make your profile and videos available to others as you
//               instruct us in using our services. We may share your data with
//               people to whom you have granted account-level access.
//             </div>{" "}
//             <div className="terms-section">
//               <b>With Your Consent : &nbsp;</b>
//               We may share your data with third parties where we have obtained
//               your express consent to do so.
//             </div>
//             <div className="terms-section">
//               <b>Authorized Vendors : &nbsp;</b>
//               We may share your data with third-party vendors that help us
//               operate our services, process orders, and comply with your
//               instructions and our contractual obligations. This includes
//               payment processors, content delivery networks (CDNs), cloud-based
//               hosting services, monitoring services, email service providers,
//               quality assurance and testing vendors, fraud and abuse prevention
//               vendors, customer relations management (CRM) vendors, and shipment
//               vendors.
//             </div>
//             <div className="terms-section">
//               <b>Advertising : &nbsp;</b>
//               We may share your data with advertising companies to display
//               relevant ads to you.
//             </div>
//             <div className="terms-section">
//               <b>Analytics : &nbsp;</b>
//               We may share your data with analytics providers who help us
//               understand how customers are using our services.
//             </div>
//             <div className="terms-section">
//               <b>Advertising : &nbsp;</b>
//               We may share your data with advertising companies to display
//               relevant ads to you.
//             </div>
//             <div className="terms-section">
//               <b>Affiliates and Advisors : &nbsp;</b>
//               We may share your data with our auditors and advisors for
//               planning, financial reporting, accounting, auditing, tax filings,
//               and legal compliance. We may share your data with our affiliates
//               to the extent necessary to provide you with the services ordered.
//             </div>
//             <div className="terms-section">
//               <b>Certain Legal Situations : &nbsp;</b>
//               We may share your data where we believe disclosure is necessary to
//               comply with a legal obligation.
//             </div>
//             <div className="terms-section">
//               <b>Aggregated or Anonymized Information : &nbsp;</b>We may
//               publicly disclose non-personal aggregated or anonymized
//               information, such as the number of visitors and registered users.
//             </div>
//             <div className="terms-section">
//               <b>Stream Seamlessly with Vimeo : &nbsp;</b>We utilize Vimeo's Advanced plan for fast, reliable streaming of authorized content. The app does not support video downloading, adhering to Vimeo's policies. Enjoy a seamless and premium viewing experience across all devices!
//             </div>
//             <div className="terms-section">
//               We use reasonable efforts to vet vendors for their privacy and
//               data security practices. We require that such vendors agree to
//               protect the data we share.
//             </div>
//             <div className="terms-header">
//               Enterprise-Administered Accounts{" "}
//             </div>
//             <div className="terms-section">
//               <ul>
//                 <li>Access information about your account activity</li>
//                 <li>Delete or limit access to your account or its content</li>
//                 <li>Change the email address associated with your account</li>
//               </ul>
//             </div>
//             <div className="terms-section">
//               Please contact the organization’s administrator for further
//               details.
//             </div>
//             <div className="terms-header">
//               Legal and Safety-Related Disclosures
//             </div>
//             <div className="terms-section">
//               We may disclose your data in response to official requests (e.g.,
//               court orders, injunctions, subpoenas, search warrants, national
//               security requests, etc.) ("requests") that we receive from
//               government authorities or parties to legal proceedings.
//             </div>
//             <div className="terms-section">
//               We may disclose user data where we reasonably believe that
//               someone's life is at risk. For example, if we become aware of a
//               person threatening to commit suicide, we may share that person's
//               data with appropriate entities that may be able to help.
//             </div>
//             <div className="terms-section">
//               We may disclose user data to report suspected crimes. We report
//               content suspected of exploiting minors to the Police of Local
//               Jurisdiction.
//             </div>
//             <div className="terms-section">
//               We may disclose user data in situations involving legal claims
//               against us or one of our users. If you submit a copyright or other
//               takedown notice, we may share that notice with the affected user.
//               If you challenge such notice, we may share your response with the
//               complainant.
//             </div>
//             <div className="terms-section">
//               We may share your data with potential transaction partners,
//               advisors, and others in the event our company is, in whole or
//               part, acquired by a third party. In such a case, we will use
//               reasonable efforts to require the acquiring entity to comply with
//               this Privacy Policy.
//             </div>
//             <div className="terms-header">Data Retention</div>
//             <div className="terms-section">
//               We retain your data for as long as you have an account. When you
//               close an account, we will delete its content, including its
//               videos. We may retain logs of automatically collected information
//               (for internal analytics and security purposes), your email
//               address, your tax information, communications with you, and your
//               transactional information (for auditing, tax, and financial
//               purposes). When we no longer have a business reason or legal
//               reason for retaining data, we will delete or anonymize it.
//             </div>{" "}
//             <div className="terms-section">
//               We retain deleted videos on our servers for a short period in case
//               you wish to reverse deletion. Once we delete a video, we may not
//               be able to recover it. If you have previously made a video public,
//               the video or its thumbnail may be discoverable in a search
//               engine's cache for a time. We have no control over search engines;
//               however, we will, upon request, send a request for deletion to
//               major search engines.
//             </div>{" "}
//             <div className="terms-section">
//               We will retain your data to comply with the legal process in
//               India. Similarly, if we believe that your account has been
//               involved in wrongdoing, we may preserve your data to defend or
//               assert our rights.
//             </div>{" "}
//             <div className="terms-header">Your Privacy Choices</div>
//             <div className="terms-section">
//               We enable you to make numerous choices about your data : &nbsp;
//             </div>
//             <div className="terms-section">
//               <ul>
//                 <li>
//                   You may choose not to provide us with certain information. For
//                   example, you may choose not to create an account or not to
//                   provide optional account information.
//                 </li>
//                 <li>
//                   You may change or correct information voluntarily submitted to
//                   us. We encourage you to keep your data current by viewing your
//                   settings page.
//                 </li>
//                 <li>
//                   You may opt out of receiving commercial emails from us. See
//                   Section 10 for more details.
//                 </li>
//                 <li>
//                   You may limit the use of cookies. See our Cookie Policy for
//                   options.
//                 </li>
//                 <li>You may back up your videos.</li>
//                 <li>
//                   You may close your account (which will delete all videos) or
//                   delete certain videos.
//                 </li>
//               </ul>
//             </div>
//             <div className="terms-header">Communications from Us </div>
//             <div className="terms-header">Emails </div>
//             <div className="terms-section">
//               We enable you to make numerous choices about your data : &nbsp;
//             </div>
//             <div className="terms-header">Mobile Device Communications </div>
//             <div className="terms-section">
//               We may, with your consent, send push notifications in our apps.
//               You may disable these by declining them or changing the app's
//               settings. To the extent provided, we may, with your consent, send
//               SMS messages to your mobile telephone for authentication and
//               security purposes. You may opt out of receiving such messages at
//               any time.{" "}
//             </div>
//             <div className="terms-header"> Protecting Your Information</div>
//             <div className="terms-section">
//               We use physical, technical, and organizational security measures
//               to safeguard your data from unauthorized or accidental disclosure.
//               Despite these efforts, no information system can be 100% secure,
//               so we cannot guarantee the absolute security of your information.
//               Users also have a role in keeping their data safe. We encourage
//               you to use a unique and hard-to-guess password for your account
//               and not to share it with others. You should only grant access
//               rights to people you know and trust and be cautious even when
//               granting all access rights. Regularly monitor your account. If you
//               believe that someone has gained access to your account without
//               your permission, please contact us immediately so that we can
//               investigate.
//             </div>
//             <div className="terms-header"> Your Responsibilities </div>
//             <div className="terms-section">
//               You may receive other people's data while using our service. If
//               you receive information from other users, you must comply with all
//               laws, including those concerning privacy, data security, and
//               online marketing.{" "}
//             </div>
//             <div className="terms-header"> Third-Party Services </div>
//             <div className="terms-section">
//               We may provide links to online services that we do not own or
//               operate. These services fall outside of this Privacy Policy, and
//               we have no control over the data they collect. For example, if you
//               click on an advertisement, you may be taken to the advertiser's
//               website. Once on a third-party site, your data may be collected by
//               others. We encourage you to read the privacy policies of such
//               services before using them.
//             </div>
//             <div className="terms-section">
//               You may use our service through third-party sites and to send your
//               offers. For example, you may watch a video embedded on a
//               third-party site or use one of our apps on a third-party platform.
//               This Privacy Policy covers our apps and video players but does not
//               cover any third-party site or any third-party video player.
//             </div>
//             <div className="terms-header">
//               {" "}
//               International Data Transfers and Certain User Rights{" "}
//             </div>
//             <div className="terms-header">Location of Data </div>
//             <div className="terms-section">
//               CiNet is based in India. We provide services globally using
//               computer systems, servers, and databases located in India and
//               other countries. When you use our services from outside of India,
//               your information may be transferred to and processed in India.
//               Please note that Indian data and privacy laws may not be as
//               comprehensive as those in your country.
//             </div>
//             <div className="terms-header">International Data Transfers </div>
//             <div className="terms-section">
//               Information that we collect may be stored, processed, and
//               transferred between any of the countries where we operate to
//               enable us to use the information in accordance with this privacy
//               policy. Information you provide may be transferred to countries
//               without data protection laws equivalent to those in your home
//               jurisdiction. Additionally, personal information you submit for
//               publication on the website will be published on the internet and
//               may be available globally. We cannot prevent the use or misuse of
//               such information by others. You expressly agree to such transfers
//               of personal information.
//             </div>
//             <div className="terms-header"> How to Contact Us</div>
//             <div className="terms-section">
//               For any questions, inquiries, or complaints related to your
//               privacy, please contact us at info@cinetshow.com
//             </div>
//             <div className="terms-header"> Policy Amendments</div>
//             <div className="terms-section">
//               We may update this Privacy Policy occasionally by posting a new
//               version on our website.
//             </div>
//             <div className="terms-header">
//               {" "}
//               Third-Party Websites or Services
//             </div>
//             <div className="terms-section">
//               This Privacy Policy does not address, and we are not responsible
//               for, the privacy, information, or other practices of any third
//               parties, including, without limitation, any third party operating
//               any site linked to our services or any third-party platform where
//               we share personal data at your request. Where personal data is
//               shared in this way, the use of the personal data will be governed
//               by the terms of the third-party platform. The inclusion of a link
//               on our site does not imply endorsement of the linked site by us.
//             </div>
//           </div>
//         </Container>
//       </div>
//     </>
//   );
// };

// export default PrivacyPolicy;

import { Container } from "@mui/material";
import React, { useEffect } from "react";
import "../../scss/PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="terms-container">
        <Container>
          <div className="terms-header">Privacy Policy</div>
          <div className="terms-content">
            <div className="terms-header">Introduction</div>
            <div className="terms-header">Who Are We and What Do We Do?</div>

            <div className="terms-section">
              This Privacy Policy outlines the practices of{" "}
              <b> FLIXADDA LLP </b>
              (collectively, <b> "CINET SHOW," </b> "we," "us," or "our"). We
              provide online on-demand films, videos, audios, and video/audio
              hosting, sharing, and related services through our
              owned-and-operated websites, including cinetshow.com, as well as
              our branded applications for mobile and connected devices
              (collectively, the "services").
            </div>

            <div className="terms-header">Acceptance and Changes</div>
            <div className="terms-section">
              By registering for, downloading, or using our services, you accept
              this Privacy Policy, which is a part of our Terms of Service. We
              may update this Privacy Policy sometimes. Any modifications will
              be posted on our website. If any changes significantly reduce our
              commitments to you, we will notify registered users by email or
              other methods.
            </div>

            <div className="terms-header">Children's Privacy</div>
            <div className="terms-section">
              We do not knowingly collect personal information from individuals
              under the minimum required ages specified herein. You must be at
              least 18 years old or the age of majority in your jurisdiction,
              whichever is greater, to use our services. Individuals under the
              applicable age may only use our services through a parent or legal
              guardian's account with their involvement. If you are a parent or
              legal guardian who believes your child has provided personal
              information to CINET SHOW without your consent, you may request
              its deletion by contacting us at info@cinetshow.com.
            </div>

            <div className="terms-header">Data We Collect About You</div>
            <div className="terms-section">
              We collect information about you when you use our services, and
              third parties may also collect information when you use our
              services. This data may include personal information that
              identifies you and non-personal information. We refer to your
              information as "your data."
            </div>

            <div className="terms-header">Account Information</div>
            <div className="terms-section">
              To create an account, you must provide a valid email address and
              password. If you choose to sign up and/or authenticate using a
              third-party account (e.g., Google), you authorize us to obtain
              account information from the third-party platform.
            </div>

            <div className="terms-header">Financial Information</div>
            <div className="terms-section">
              To make use of our service, you need to pay through a payment
              gateway, governed by our Terms and Conditions. Your payment
              information will be collected and processed by our authorized
              payment vendors. We do not directly collect or store credit or
              debit card numbers ourselves during transaction processing. If a
              service is made through a third-party platform (i.e., in-app
              purchase), the payment method stored with that platform will be
              charged.
            </div>

            <div className="terms-header">Content</div>
            <div className="terms-section">
              Account holders may create an account and upload content such as
              videos, text, photographs, and artwork. Your profile may display
              information about you and your activities, which others can
              access. Your videos may be viewed and accessed by others, and
              associated metadata (e.g., titles, descriptions, tags, etc.) will
              be visible to others. Your interactions with other users (e.g.,
              comments, "likes," private messages) may be visible to others. You
              may choose to limit the availability of your profile and videos.
            </div>

            <div className="terms-header">Other Information You May Submit</div>
            <div className="terms-section">
              You may submit data to us for limited purposes such as requesting
              customer support, answering a questionnaire, participating in a
              study or survey, entering contests or sweepstakes, or signing up
              to receive communications from us or another user.
            </div>

            <div className="terms-header">
              Automatically Collected Information
            </div>
            <div className="terms-section">
              We automatically collect certain types of data when you use our
              services, regardless of whether you have an account. This data
              includes your IP address, technical information about your device
              (e.g., browser type and version, operating system, basic device
              information, geographical location, referral source, length of
              visit, page views, website navigation), the web page you visited
              or search query you entered before reaching us, and your
              activities. We may track your activities using cookies and similar
              technologies. By using our services, you agree to our use of these
              methods as outlined in our Cookie Policy.
            </div>

            <div className="terms-header">
              Information Collected by Third Parties
            </div>
            <div className="terms-section">
              Some third parties may collect data about you when you use our
              services. This may include data you submit (such as payment
              information) or automatically collected information (in the case
              of third-party analytics providers and advertising partners).
            </div>
            <div className="terms-section">
              We may obtain data from third parties about you and combine it
              with the information we have collected. For example, some
              advertisers or advertising platforms may allow us to determine
              what other online services you might use so that we may place
              relevant ads on those services.
            </div>

            <div className="terms-header">How We Use Your Data</div>
            <div className="terms-section">
              We may use your data for the following purposes:
              <ul>
                <li>
                  Identification and Authentication: We use your data to verify
                  you when you access your account.
                </li>
                <li>
                  Operating Our Services: We use your data to provide our
                  services, process and fulfill orders, provide customer
                  support, and comply with our contractual obligations to you.
                </li>
                <li>
                  Improving Our Services: We use your data to understand how our
                  services are being used and how we can improve them.
                  Generally, we analyze aggregated data rather than specific
                  user data. However, we may need to analyze a specific case to
                  address a specific problem (e.g., a bug affecting a few
                  accounts).
                </li>
                <li>
                  Customizing Your Experience: We use your data to personalize
                  the service for you. This may include remembering your
                  preferences for language or volume or displaying videos that
                  you might enjoy based on your viewing choices.
                </li>
                <li>
                  Marketing and Advertising: We use your data to show you ads on
                  third-party sites and deliver third-party advertisements to
                  you. This may include "targeted ads" based on your activities.
                </li>
                <li>
                  Exercising Our Rights: Where reasonably necessary, we use your
                  data to exercise our legal rights and prevent abuse of our
                  service. For example, we may use your data to detect and
                  prevent fraud, spam, or content that violates our Terms of
                  Service.
                </li>
                <li>
                  Legal Compliance: We use your data where we are legally
                  required to do so. For example, we may need to gather your
                  data to respond to a subpoena or court order.
                </li>
                <li>
                  Protecting Your Information: Where appropriate, we may
                  anonymize, back up, and delete certain data.
                </li>
              </ul>
              <p>
                {" "}
                We may use algorithms and other automated means to implement any
                of the above.{" "}
              </p>
              <p>
                {" "}
                Whenever we process your data for one of these purposes, we have
                determined that one or more of the following lawful bases apply:{" "}
              </p>
              <li>Performance of a contract;</li>
              <li>Legitimate commercial interest;</li>
              <li>Compliance with a legal obligation; or</li>
              <li>Consent.</li>
              <p>
                {" "}
                If you require further information about the balancing test that
                cinetshow has undertaken to justify its reliance on legitimate
                interest as a lawful basis of processing.{" "}
              </p>
            </div>

            <div className="terms-header">With Whom We Share Your Data</div>
            <div className="terms-section">
              We share data with third parties as follows:
              <ul>
                <li>
                  As You Instruct: We may make your profile and videos available
                  to others as you instruct us in using our services. We may
                  share your data with people to whom you have granted
                  account-level access.
                </li>
                <li>
                  With Your Consent: We may share your data with third parties
                  where we have obtained your express consent to do so.
                </li>
                <li>
                  Authorized Vendors: We may share your data with third-party
                  vendors that help us operate our services, process orders, and
                  comply with your instructions and our contractual obligations.
                  This includes payment processors, content delivery networks
                  (CDNs), cloud-based hosting services, monitoring services,
                  email service providers, quality assurance and testing
                  vendors, fraud and abuse prevention vendors, customer
                  relations management (CRM) vendors, and shipment vendors.
                </li>
                <li>
                  Advertising: We may share your data with advertising companies
                  to display relevant ads to you.
                </li>
                <li>
                  Analytics: We may share your data with analytics providers who
                  help us understand how customers are using our services.
                </li>
                <li>
                  Affiliates and Advisors: We may share your data with our
                  auditors and advisors for planning, financial reporting,
                  accounting, auditing, tax filings, and legal compliance. We
                  may share your data with our affiliates to the extent
                  necessary to provide you with the services ordered.
                </li>
                <li>
                  Certain Legal Situations: We may share your data where we
                  believe disclosure is necessary to comply with a legal
                  obligation.
                </li>
                <li>
                  Aggregated or Anonymized Information: We may publicly disclose
                  non-personal aggregated or anonymized information, such as the
                  number of visitors and registered users. We use reasonable
                  efforts to vet vendors for their privacy and data security
                  practices. We require that such vendors agree to protect the
                  data we share.
                </li>
              </ul>
            </div>

            <div className="terms-header">Enterprise-Administered Accounts</div>
            <div className="terms-section">
              Where the services are provided to you through your employer or
              another organization that administers access, that organization
              may be able to:
              <ul>
                <li>Access information about your account activity;</li>
                <li>
                  Delete or limit access to your account or its content; and/or
                </li>
                <li>Change the email address associated with your account.</li>
              </ul>
              Please contact the organization’s administrator for further
              details.
            </div>

            <div className="terms-header">
              Legal and Safety-Related Disclosures
            </div>
            <div className="terms-section">
              We may disclose your data in response to official requests (e.g.,
              court orders, injunctions, subpoenas, search warrants, national
              security requests, etc.) ("requests") that we receive from
              government authorities or parties to legal proceedings.
            </div>
            <div className="terms-section">
              We may disclose user data where we reasonably believe that
              someone's life is at risk. For example, if we become aware of a
              person threatening to commit suicide, we may share that person's
              data with appropriate entities that may be able to help.
            </div>
            <div className="terms-section">
              We may disclose user data to report suspected crimes. We report
              content suspected of exploiting minors to the Police of Local
              Jurisdiction.
            </div>
            <div className="terms-section">
              We may disclose user data in situations involving legal claims
              against us or one of our users. If you submit a copyright or other
              takedown notice, we may share that notice with the affected user.
              If you challenge such notice, we may share your response with the
              complainant.
            </div>
            <div className="terms-section">
              We may share your data with potential transaction partners,
              advisors, and others in the event our company is, in whole or
              part, acquired by a third party. In such a case, we will use
              reasonable efforts to require the acquiring entity to comply with
              this Privacy Policy.
            </div>

            <div className="terms-header">Data Retention</div>
            <div className="terms-section">
              We retain your data for as long as you have an account. When you
              close an account, we will delete its content, including its
              videos. We may retain logs of automatically collected information
              (for internal analytics and security purposes), your email
              address, your tax information, communications with you, and your
              transactional information (for auditing, tax, and financial
              purposes). When we no longer have a business reason or legal
              reason for retaining data, we will delete or anonymize it.
            </div>
            <div className="terms-section">
              We retain deleted videos on our servers for a short period in case
              you wish to reverse deletion. Once we delete a video, we may not
              be able to recover it. If you have previously made a video public,
              the video or its thumbnail may be discoverable in a search
              engine's cache for a time. We have no control over search engines;
              however, we will, upon request, send a request for deletion to
              major search engines.
            </div>
            <div className="terms-section">
              We will retain your data to comply with the legal process in
              India. Similarly, if we believe that your account has been
              involved in wrongdoing, we may preserve your data to defend or
              assert our rights.{" "}
            </div>

            <div className="terms-header">Your Privacy Choices</div>
            <div className="terms-section">
              We enable you to make numerous choices about your data:
              <ul>
                <li>
                  You may choose not to provide us with certain information. For
                  example, you may choose not to create an account or not to
                  provide optional account information.
                </li>
                <li>
                  You may change or correct information voluntarily submitted to
                  us. We encourage you to keep your data current by viewing your
                  settings page.
                </li>
                <li>You may close your account.</li>
              </ul>
            </div>

            <div className="terms-header">Communications from Us</div>
            <div className="terms-header">Emails</div>
            <div className="terms-section">
              If you create an account, you may receive commercial emails from
              us, including newsletters and offers. You may always opt out of
              receiving commercial messages in your account settings. Please
              note that any opt-out request may take several days to process,
              and you will continue to receive transactional emails from us
              (e.g., emails confirming transactions or providing information
              about your account).
            </div>

            <div className="terms-header">Mobile Device Communications</div>
            <div className="terms-section">
              We may, with your consent, send push notifications in our apps.
              You may disable these by declining them or changing the app's
              settings. To the extent provided, we may, with your consent, send
              SMS messages to your mobile telephone for authentication and
              security purposes. You may opt out of receiving such messages at
              any time.
            </div>

            <div className="terms-header">Protecting Your Information</div>
            <div className="terms-section">
              We use physical, technical, and organizational security measures
              to safeguard your data from unauthorized or accidental disclosure.
              Despite these efforts, no information system can be 100% secure,
              so we cannot guarantee the absolute security of your information.
              Users also have a role in keeping their data safe. We encourage
              you to use a unique and hard-to-guess password for your account
              and not to share it with others. You should only grant access
              rights to people you know and trust and be cautious even when
              granting all access rights. Regularly monitor your account. If you
              believe that someone has gained access to your account without
              your permission, please contact us immediately so that we can
              investigate.
            </div>

            <div className="terms-header">Your Responsibilities</div>
            <div className="terms-section">
              You may receive other people's data while using our service. If
              you receive information from other users, you must comply with all
              laws, including those concerning privacy, data security, and
              online marketing.
            </div>

            <div className="terms-header">Third-Party Services</div>
            <div className="terms-section">
              We may provide links to online services that we do not own or
              operate. These services fall outside of this Privacy Policy, and
              we have no control over the data they collect. For example, if you
              click on an advertisement, you may be taken to the advertiser's
              website. Once on a third-party site, your data may be collected by
              others. We encourage you to read the privacy policies of such
              services before using them.
            </div>

            <div className="terms-section">
              You may use our service through third-party sites and to send your
              offers. For example, you may watch a video embedded on a
              third-party site or use one of our apps on a third-party platform.
              This Privacy Policy covers our apps and video players but does not
              cover any third-party site or any third-party video player.
            </div>

            <div className="terms-header">
              International Data Transfers and Certain User Rights{" "}
            </div>
            <div className="terms-header">Location of Data </div>

            <div className="terms-section">
              CINET SHOW is based in India. We provide services globally using
              computer systems, servers, and databases located in India and
              other countries. When you use our services from outside of India,
              your information may be transferred to and processed in India.
              Please note that Indian data and privacy laws may not be as
              comprehensive as those in your country.
            </div>
            <div className="terms-header"> International Data Transfers </div>
            <div className="terms-section">
              Information that we collect may be stored, processed, and
              transferred between any of the countries where we operate to
              enable us to use the information in accordance with this privacy
              policy. Information you provide may be transferred to countries
              without data protection laws equivalent to those in your home
              jurisdiction. Additionally, personal information you submit for
              publication on the website will be published on the internet and
              may be available globally. We cannot prevent the use or misuse of
              such information by others. You expressly agree to such transfers
              of personal information.
            </div>

            <div className="terms-header">How to Contact Us</div>
            <div className="terms-section">
              For any questions, inquiries, or complaints related to your
              privacy, please contact us at info@cinetshow.com.
            </div>

            <div className="terms-header">Policy Amendments</div>
            <div className="terms-section">
              We may update this Privacy Policy occasionally by posting a new
              version on our website.
            </div>

            <div className="terms-header">Third-Party Websites or Services</div>
            <div className="terms-section">
              This Privacy Policy does not address, and we are not responsible
              for, the privacy, information, or other practices of any third
              parties, including, without limitation, any third party operating
              any site linked to our services or any third-party platform where
              we share personal data at your request. Where personal data is
              shared in this way, the use of the personal data will be governed
              by the terms of the third-party platform. The inclusion of a link
              on our site does not imply endorsement of the linked site by us.
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PrivacyPolicy;
