import React  from "react";
import {
  Box,
  Link,
  // Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Instagram, YouTube, X } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "./footer.css";
import Logo from "../../images/cinetshow2.svg";

const Footer = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (page) => {
    if (page === "First Show Movies") {
      navigate(`/movies/first-show`);
    }
    if (page === "Subscribed Movies") {
      navigate(`/movies/subscribe`);
    }
    if (page === "Watchlist") {
      navigate(`/movies/watch-list`);
    }
    handleClose();
  };
  return (
    <Box component="footer" className="footer">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        {/* <Typography
          variant="h6"
          component="div"
          sx={{
            marginBottom: "5px",
            paddingLeft: "5px",
            fontFamily: "DM Sans",
            fontSize: "32px",
            fontWeight: "700",
            color: "#FFF",
          }}
        >
          CiNet
        </Typography> */}
        <img
          src={Logo}
          alt="Logo"
          height={80}
          style={{
            objectFit: "contain",
          }}
        />
        {/* <Box>
          <IconButton
            sx={{ color: "#fff", background: "#252525", mr: 1.5 }}
            href=""
          >
            <X />
          </IconButton>
          <IconButton
            sx={{ color: "#fff", background: "#252525", mr: 1.5 }}
            href=""
          >
            <Instagram />
          </IconButton>
          <IconButton
            sx={{ color: "#fff", background: "#252525", mr: 1.5 }}
            href=""
          >
            <YouTube />
          </IconButton>
        </Box> */}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "flex-start" },
          fontFamily: "DM Sans",
          fontWeight: "400",
          fontSize: "16px",
          color: "#E6E6E6",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            marginBottom: "10px",
            // marginRight: "50px",
          }}
        >
          <Link
            onClick={() => navigate("/home")}
            color="inherit"
            underline="none"
            style={{ cursor: "pointer" }}
          >
            Home
          </Link>
          {/* <Link
            onClick={() => window.scrollTo(0, 0)}
            color="inherit"
            underline="none"
            style={{ cursor: "pointer" }}
          >
            Movies
          </Link> */}
          {/* <div onClick={handleOpen} style={{ cursor: "pointer" }}>
            Movies
          </div>
          <Menu
            id="menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "button",
            }}
            sx={{
              width: "281px",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                backgroundColor: "transparent",
                "& .MuiMenuItem-root": {
                  color: "#fff",
                  borderRadius: "16px",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  textAlign: "left",
                },
                "& .MuiList-root": {
                  paddingBottom: 0,
                  paddingTop: 0,
                  borderRadius: "16px",
                  padding: "24px 24px 30px 24px",
                  background: "#000",
                  width: "281px",
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "#000",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            keepMounted
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {["First Show Movies", "Subscribed Movies", "Watchlist"].map(
              (item) => (
                <MenuItem
                  key={item}
                  onClick={() => {
                    handleMenuItemClick(item);
                  }}
                >
                  {item}
                </MenuItem>
              )
            )}
          </Menu>
          <Link
            onClick={() => navigate("/language")}
            color="inherit"
            underline="none"
            style={{ cursor: "pointer" }}
          >
            Language
          </Link> */}
          <Link
            onClick={() => navigate("/contact-us")}
            color="inherit"
            underline="none"
            style={{ cursor: "pointer" }}
          >
            Contact Us
          </Link>
          {/* <Link
            onClick={() => navigate("/about-us")}
            color="inherit"
            underline="none"
            style={{ cursor: "pointer" }}
          >
            About Us
          </Link> */}
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Link
            onClick={() => navigate("/privacy-policy")}
            color="inherit"
            underline="none"
            style={{ cursor: "pointer" }}
          >
            Privacy Policy
          </Link>
          <Link
            onClick={() => navigate("/terms-condition")}
            color="inherit"
            underline="none"
            style={{ cursor: "pointer" }}
          >
            Terms & Condition
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
