import React from "react";
import { Grid, Typography } from "@mui/material";
import ScreenImg2 from "../../images/screen2.png";
import "../../scss/Contact.scss";

const CinetSays = () => {
  return (
    <Grid container className="screen-body pt-lg-5">
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            py: { xs: "1rem", md: 7 },
            px: { xs: "1rem", md: 10 },
          }}
        >
          <div className="mt-5 pt-3">
            <Typography variant="para" className="screenPara1">
              CINET
            </Typography>
            <br />
            <Typography variant="para" className="screenPara2">
              SAYS
            </Typography>
            <br />
            <Typography variant="para" className="screenPara3">
              Don't worry guys, we are here to serve both of you. As you are
              aware, these days movies are not meant only for cinema halls.
              Through smartphones, Android TVs, laptops, Tabs and other devices,
              movies can be viewed within the comfort of your homes or any other
              place of your choice. Using this technology, CINET streams brand
              new films on its platform parallelly while the films are screened
              in theatres. Film buffs from any part of the world can enjoy
              watching new films on our platform. Our primary goal is to reach
              as many viewers as possible on the first day of the film release
              itself.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sx={6}>
          <div>
            <img src={ScreenImg2} alt="Contact" />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CinetSays;
