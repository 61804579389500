import React, { useEffect, useState } from "react";
import "../../scss/LanguagePage.scss";
import { Typography } from "@mui/material";
import Strings from "../../common/string";
import { Grid } from "@mui/material";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useNavigate } from "react-router-dom";
import { languagelist } from "../../services/language";

import { Toaster, toast } from "react-hot-toast";
import { Spinner } from "react-bootstrap";

export default function LanguagePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const [value, setValue] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLanguage();
  }, []);

  const search = "";
  const getLanguage = async () => {
    setLoading(true);
    try {
      const response = await languagelist(search);
      if (response && response?.data?.status) {
        setValue(response.data?.data?.languages);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching languages:", error);
      setLoading(false);
    }
  };

  const handleCardClick = (id) => {
    setSelectedCardId(id);
  };

  const handleClick = () => {
    if (selectedCardId) {
      navigate("/home", { state: { languageId: selectedCardId } });
      localStorage.setItem("languageId", selectedCardId);
    } else {
      toast.error("Please select a language or use skip button");
    }
  };

  const handleSkip = () => {
    navigate("/home", { state: { languageId: "" } });
    localStorage.setItem("languageId", "");
  };

  return (
    <>
      <Toaster />
      <div className="Language-main">
        <div className="mb-4">
          <div className="Language-header-title">
            <Typography className="Language-header">
              {Strings.SELECT_LANGUAGE}
            </Typography>
            <Button
              className="Continue-Button-intropage"
              variant="secondary"
              onClick={handleSkip}
              style={{ marginLeft: "10px" }}
            >
              Skip
              <KeyboardDoubleArrowRightIcon style={{ marginTop: "2px" }} />
            </Button>
          </div>
          <div className="Language-sub-title">
            <Typography className="sub-title">{Strings.SORT_TEXT}</Typography>
          </div>
        </div>
        <div className="main-container">
          <div>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  minHeight: "50vh",
                }}
              >
                <Spinner animation="border" role="status" />
              </div>
            ) : (
              <Grid container spacing={2}>
                {value.map((card) => (
                  <Grid item xs={12} sm={6} md={3} key={card.id}>
                    <Card
                      className={`custom-card ${
                        selectedCardId === card.id ? "selected" : ""
                      }`}
                      onClick={() => handleCardClick(card.id)}
                    >
                      <div
                        className={`card-background ${
                          selectedCardId === card.id ? "selected" : ""
                        }`}
                        style={{
                          backgroundImage: `url(${card.image})`,
                        }}
                      ></div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
          <div
            className="Language-continue"
            style={{ marginTop: "20px", marginBottom: "20px", height: "30px" }}
          >
            <Button
              className="Continue-Button-intropage"
              variant="secondary"
              onClick={handleClick}
              sx={{ mx: 2 }}
            >
              {Strings.CONTINUE_BUTTON}
              <KeyboardDoubleArrowRightIcon style={{ marginTop: "2px" }} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
