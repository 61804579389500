import axios from "axios";
import { urlConfig } from "../../config/urlConfig";

const HEADERSMULTIPART = {
  "Api-Version": "v1",
  responseType: "application/json",
  "Content-Type": "multipart/form-data",
};

const axiosInstance = axios.create({
  baseURL: `${urlConfig.baseUrl}`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const FileUploadInstance = axios.create({
  baseURL: `${urlConfig.baseUrl}`,
  headers: HEADERSMULTIPART,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

// Interceptor to add token to requests for file upload
FileUploadInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor to add token to general requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const ApiService = {
  post: (url, data) => axiosInstance.post(url, data),
  get: (url) => axiosInstance.get(url),
  delete: (url) => axiosInstance.delete(url),
};

export const FileUploadService = {
  post: (url, data) => FileUploadInstance.post(url, data),
};
