import React from "react";
import { Grid, Typography } from "@mui/material";
import ScreenImg1 from "../../images/screen1.png";
import "../../scss/Contact.scss";

const Screen1 = () => {
  return (
    <Grid container className="screen-body">
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            // py: { xs: "1rem", md: 7 },
            px: { xs: "1rem", md: 10 },
          }}
        >
          <div className="mt-5 pt-3">
            <Typography variant="para" className="screenPara1">
              FILMMAKER
            </Typography>
            <br />
            <Typography variant="para" className="screenPara2">
              SAYS
            </Typography>
            <br />
            <Typography variant="para" className="screenPara3">
              We make movies with passion so much so that we like to show it to
              every viewer and make them happy. Watching films on big screen is
              unmatchable. Unfortunately, cinema halls are disappearing in
              suburban and rural areas. Also it is very difficult to get
              theatres in other states and abroad. Meanwhile, there is a
              considerable decrease in the number of audience watching films in
              theatres.{" "}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sx={6}>
          <div>
            <img src={ScreenImg1} alt="Contact" />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Screen1;
