

// import React, { useState, useEffect } from "react";
// import { useDropzone } from "react-dropzone";
// import { Typography, Card, Box } from "@mui/material";
// import CloudUploadIcon from "../../images/UploadMoviePage/Featuredicon.svg";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import { Toaster, toast } from "react-hot-toast";
// import Spinner from "react-bootstrap/Spinner";
// import LinearProgress from "@mui/material/LinearProgress";
// import { styled } from "@mui/material/styles";
// import { urlConfig } from "../../config/urlConfig";

// const CHUNK_SIZE = 10 * 1024 * 1024;

// const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: "10px",
//   width: "100%",
//   backgroundColor: "#ffffff",
//   "& .MuiLinearProgress-bar": {
//     backgroundImage:
//       "repeating-linear-gradient(45deg, #fed530 0%, #fed530 25%, #fff 25%, #fff 50%)",
//     backgroundSize: "10px 10px",
//   },
// }));

// const SongVideoUpload = ({ onUpload, uploadedUrl }) => {
//   const token = localStorage.getItem("token");
//   const [loading, setLoading] = useState(false);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [videoSrc, setVideoSrc] = useState(uploadedUrl);

//   useEffect(() => {
//     setVideoSrc(uploadedUrl);
//   }, [uploadedUrl]);

//   const onDrop = (acceptedFiles) => {
//     const file = acceptedFiles[0];
//     console.log(file);
//     handleVideoUpload(file);
//   };

//   const { getRootProps, getInputProps, open } = useDropzone({
//     onDrop,
//     noClick: true,
//     maxSize: 100000000000,
//     accept: "video/*",
//   });

//   const handleVideoUpload = async (file) => {
//     try {
//       setLoading(true);
//       setUploadProgress(0);

//       const fileSize = file.size;
//       const resp = await fetch(
//         urlConfig?.baseUrl + urlConfig?.common?.videoupload,
//         {
//           method: "POST",
//           body: JSON.stringify({ size: fileSize }),
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: token ? `Bearer ${token}` : undefined,
//           },
//         }
//       );
//       const data = await resp.json();
//       console.log(data?.data, "uploaded data");

//       let startByte = 0;
//       while (startByte < fileSize) {
//         const endByte = Math.min(startByte + CHUNK_SIZE, fileSize);
//         const chunk = file.slice(startByte, endByte);
//         const chunkResp = await fetch(data?.data?.vimeo_video_upload_url, {
//           method: "PATCH",
//           headers: {
//             "Content-Type": "application/offset+octet-stream",
//             "Tus-Resumable": "1.0.0",
//             "Upload-Offset": startByte,
//           },
//           body: chunk,
//         });

//         if (!chunkResp.ok) {
//           throw new Error("Chunk upload failed");
//         }

//         setUploadProgress(Math.ceil((endByte / fileSize) * 100));
//         startByte = endByte;
//       }

//       toast.success("Video uploaded successfully.");
//       setVideoSrc(data?.data?.vimeo_video_upload_url);
//       if (onUpload) {
//         onUpload(data?.data?.vimeo_video_id);
//       }
//     } catch (error) {
//       console.error("Error uploading video:", error);
//       toast.error("An error occurred while uploading the video.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div>
//       <Toaster />
//       {!videoSrc ? (
//         <Card
//           className="upload-card"
//           sx={{ backgroundColor: "#000" }}
//           {...getRootProps()}
//         >
//           <input {...getInputProps()} />
//           <div style={{ textAlign: "center" }}>
//             {loading ? (
//               <>
//                 <Spinner animation="border" role="status" />
//                 <Typography variant="body2" style={{ color: "white" }}>
//                   {`${uploadProgress}% Uploading...`}
//                 </Typography>
//                 <Box
//                   sx={{
//                     width: "100%",
//                     margin: "10px auto",
//                     backgroundColor: "#fff",
//                   }}
//                 >
//                   <CustomLinearProgress
//                     variant="determinate"
//                     value={uploadProgress}
//                   />
//                 </Box>
//               </>
//             ) : (
//               <>
//                 <img
//                   src={CloudUploadIcon}
//                   alt="Upload Icon"
//                   onClick={open}
//                   style={{ height: "60px", width: "60px" }}
//                 />
//                 <Typography
//                   variant="body1"
//                   component="p"
//                   className="upload-card-text"
//                   onClick={open}
//                 >
//                   Select file to upload
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   component="p"
//                   style={{ color: "white" }}
//                   onClick={open}
//                 >
//                   or drag and drop
//                 </Typography>
//               </>
//             )}
//           </div>
//         </Card>
//       ) : (
//         <Card
//           className="upload-card"
//           sx={{ backgroundColor: "#000" }}
//           {...getRootProps()}
//         >
//           <input {...getInputProps()} />
//           <div style={{ textAlign: "center" }}>
//             {loading ? (
//               <Spinner animation="border" role="status" />
//             ) : (
//               <>
//                 <img
//                   src={CloudUploadIcon}
//                   alt="Upload Icon"
//                   onClick={open}
//                   style={{ height: "60px", width: "60px" }}
//                 />
//                 <Box display="flex" alignItems="center" justifyContent="center">
//                   <Typography
//                     variant="body1"
//                     component="p"
//                     className="upload-card-text"
//                     onClick={open}
//                   >
//                     Uploaded Successfully
//                   </Typography>
//                   <CheckCircleIcon sx={{ color: "green", marginLeft: "8px" }} />
//                 </Box>
//                 <Typography
//                   variant="body2"
//                   component="p"
//                   style={{ color: "white" }}
//                   onClick={open}
//                 >
//                   Change Video
//                 </Typography>
//               </>
//             )}
//           </div>
//         </Card>
//       )}
//     </div>
//   );
// };

// export default SongVideoUpload;
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, Card, Box } from "@mui/material";
import CloudUploadIcon from "../../images/UploadMoviePage/Featuredicon.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Toaster, toast } from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { urlConfig } from "../../config/urlConfig";

const CHUNK_SIZE = 10 * 1024 * 1024;

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "10px",
  width: "100%",
  backgroundColor: "#ffffff",
  "& .MuiLinearProgress-bar": {
    backgroundImage:
      "repeating-linear-gradient(45deg, #fed530 0%, #fed530 25%, #fff 25%, #fff 50%)",
    backgroundSize: "10px 10px",
  },
}));

const SongVideoUpload = ({ onUpload, uploadedUrl }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoSrc, setVideoSrc] = useState(uploadedUrl);

  useEffect(() => {
    setVideoSrc(uploadedUrl);
  }, [uploadedUrl]);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.type.startsWith("video/")) {
      handleVideoUpload(file);
    } else {
      toast.error("Please upload a valid video file.");
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    maxSize: 100000000000,
    accept: "video/*",
  });

  const handleVideoUpload = async (file) => {
    try {
      setLoading(true);
      setUploadProgress(0);

      const fileSize = file.size;
      const resp = await fetch(
        urlConfig?.baseUrl + urlConfig?.common?.videoupload,
        {
          method: "POST",
          body: JSON.stringify({ size: fileSize }),
          headers: {
            "Content-Type": "application/json",
            Authorization: token ? `Bearer ${token}` : undefined,
          },
        }
      );
      const data = await resp.json();
      console.log(data?.data, "uploaded data");

      let startByte = 0;
      while (startByte < fileSize) {
        const endByte = Math.min(startByte + CHUNK_SIZE, fileSize);
        const chunk = file.slice(startByte, endByte);
        const chunkResp = await fetch(data?.data?.vimeo_video_upload_url, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/offset+octet-stream",
            "Tus-Resumable": "1.0.0",
            "Upload-Offset": startByte,
          },
          body: chunk,
        });

        if (!chunkResp.ok) {
          throw new Error("Chunk upload failed");
        }

        setUploadProgress(Math.ceil((endByte / fileSize) * 100));
        startByte = endByte;
      }

      toast.success("Video uploaded successfully.");
      setVideoSrc(data?.data?.vimeo_video_upload_url);
      if (onUpload) {
        onUpload(data?.data?.vimeo_video_id);
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      toast.error("An error occurred while uploading the video.");
    } finally {
      setLoading(false);
    }
  };

  const handleChangeVideoClick = () => {
    // Resetting the video source and upload states to allow for a new upload
    setVideoSrc(null);
    setLoading(false);
    setUploadProgress(0);
  };

  return (
    <div>
      <Toaster />
      {!videoSrc ? (
        <Card
          className="upload-card"
          sx={{ backgroundColor: "#000" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div style={{ textAlign: "center" }}>
            {loading ? (
              <>
                <Spinner animation="border" role="status" />
                <Typography variant="body2" style={{ color: "white" }}>
                  {`${uploadProgress}% Uploading...`}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    margin: "10px auto",
                    backgroundColor: "#fff",
                  }}
                >
                  <CustomLinearProgress
                    variant="determinate"
                    value={uploadProgress}
                  />
                </Box>
              </>
            ) : (
              <>
                <img
                  src={CloudUploadIcon}
                  alt="Upload Icon"
                  onClick={open}
                  style={{ height: "60px", width: "60px" }}
                />
                <Typography
                  variant="body1"
                  component="p"
                  className="upload-card-text"
                  onClick={open}
                >
                  Select file to upload
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  style={{ color: "white" }}
                  onClick={open}
                >
                  or drag and drop
                </Typography>
              </>
            )}
          </div>
        </Card>
      ) : (
        <Card
          className="upload-card"
          sx={{ backgroundColor: "#000" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div style={{ textAlign: "center" }}>
            {loading ? (
              <Spinner animation="border" role="status" />
            ) : (
              <>
                <img
                  src={CloudUploadIcon}
                  alt="Upload Icon"
                  onClick={handleChangeVideoClick}
                  style={{ height: "60px", width: "60px" }}
                />
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography
                    variant="body1"
                    component="p"
                    className="upload-card-text"
                    onClick={handleChangeVideoClick}
                  >
                    Uploaded Successfully
                  </Typography>
                  <CheckCircleIcon sx={{ color: "green", marginLeft: "8px" }} />
                </Box>
                <Typography
                  variant="body2"
                  component="p"
                  style={{ color: "white" }}
                  onClick={handleChangeVideoClick}
                >
                  Change Video
                </Typography>
              </>
            )}
          </div>
        </Card>
      )}
    </div>
  );
};

export default SongVideoUpload;

