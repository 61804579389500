// import { Container } from "@mui/material";
// import React, { useEffect } from "react";
// import "../../scss/TermsAndConditions.scss";

// const TermsAndCondition = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <>
//       <div className="terms-container">
//         <Container>
//           <div className="terms-header">Terms and Conditions</div>
//           <div className="terms-content">
//             <div className="terms-header">Who We Are and What We Do </div>
//             <div className="terms-section">
//               This Terms and Conditions outlines the practices of FLIXADDA LLP
//               (collectively, "FLIXADDA," "we," "us," or "our") and its product
//               "CiNet." We provide online on-demand films, videos, audios, and
//               video/audio hosting, sharing, and related services through our
//               owned-and-operated websites, including CiNet.com, as well as our
//               branded applications for mobile and connected devices
//               (collectively, the "services"). Video/Audios and other
//               entertainment materials hosted on our services are collectively
//               referred to as "Contents."
//             </div>
//             <div className="terms-header">Applicable Terms</div>
//             <div className="terms-section">
//               Your use of the Service is subject to these terms, our Privacy
//               Policy, and Copyright Policy, which may be updated (together, this
//               "Agreement"). Any other links or references provided in these
//               terms are for informational purposes only and are not part of the
//               Agreement.
//             </div>
//             <div className="terms-section">
//               Please read this Agreement carefully and ensure you understand it.
//               If you do not understand the Agreement or do not accept any part
//               of it, you may not use the Service.
//             </div>{" "}
//             <div className="terms-section">
//               By accepting this Agreement, you affirm that you are 18 years of
//               age or older and are fully competent to enter into this Agreement
//               and to abide by and comply with its terms. If you are under 18
//               years of age, you must have permission from a parent or guardian
//               as provided below.
//             </div>
//             <div className="terms-header">
//               Permission by Parent or Guardian{" "}
//             </div>
//             <div className="terms-section">
//               If you are considered a minor in your country, you represent that
//               you have your parent or guardian's permission to use the Service.
//               Please have them read this Agreement with you.
//             </div>
//             <div className="terms-section">
//               If you are a parent or legal guardian of a minor in your country,
//               by allowing your child to use the Service, you are subject to the
//               terms of this Agreement and responsible for your child's activity
//               on the Service.
//             </div>
//             <div className="terms-header">Businesses </div>
//             <div className="terms-section">
//               If you are using the Service on behalf of a company or
//               organization, you represent that you have the authority to act on
//               behalf of that entity and that such entity accepts this Agreement.
//             </div>
//             <div className="terms-header">Passwords & Account Access </div>
//             <div className="terms-section">
//               You exercise control over your CiNet user account through your
//               password. To maintain exclusive control, you should not reveal
//               your password to anyone and should maintain control over the
//               devices used to access the CiNet service. The CiNet service and
//               any content viewed through the service are for your personal and
//               non-commercial use only and must not be shared with anyone. Be
//               cautious of any communication requesting that you submit credit
//               card or other account information. Providing your information in
//               response to such communications can result in identity theft.
//               Always access your sensitive account information by going directly
//               to the CiNet website and not through a hyperlink in an email or
//               any other electronic communication, even if it looks official.
//               FLIXADDA reserves the right to place any account on hold anytime,
//               with or without notification to the subscriber or member, to
//               protect itself and its affiliates from what it believes to be
//               fraudulent activity.
//             </div>
//             <div className="terms-header">Your Use of the Service </div>
//             <div className="terms-header">Content on the Service </div>
//             <div className="terms-section">
//               The content on the Service includes videos, audio (for example,
//               music and other sounds) (collectively, "Content"). Content is the
//               responsibility of the person or entity that provides it to the
//               Service. FLIXADDA is under no obligation to verify the content for
//               compliance with any law, copyright, morality, religion, or any
//               government or court orders. If you see any Content, you believe
//               does not comply with this Agreement, including violations of
//               copyright or the law, you can report it to us.
//             </div>
//             <div className="terms-header">Your Information </div>
//             <div className="terms-section">
//               Our Privacy Policy explains how we treat your personal data and
//               protect your privacy when you use the Service.
//             </div>
//             <div className="terms-section">
//               The following restrictions apply to your use of the Service. You
//               are not allowed to:
//             </div>
//             <div className="terms-section">
//               <ul>
//                 <li>
//                   Access, reproduce, download, distribute, transmit, broadcast,
//                   display, sell, license, alter, modify, or otherwise use any
//                   Content except: as expressly authorized by FLIXADDA or the
//                   Content Owner; or with prior written permission from FLIXADDA
//                   or the Content Owner;
//                 </li>{" "}
//                 <li>
//                   Circumvent, disable, fraudulently engage with, or otherwise
//                   interfere with any part of the Service (or attempt to do any
//                   of these things), including security-related features or
//                   features that: allow the copying or other use of Content; or
//                   limit the use of the Service or Content;
//                 </li>
//                 <li>
//                   Access the Service using any automated means (such as robots,
//                   botnets, or scrapers) except with FLIXADDA's prior written
//                   permission;
//                 </li>
//                 <li>
//                   Collect or harvest any information that might identify a
//                   person (for example, usernames), unless permitted by that
//                   person or allowed under the relevant section above;
//                 </li>
//                 <li>
//                   Use the Service to distribute unsolicited promotional or
//                   commercial content or other unwanted or mass solicitations;
//                 </li>
//                 <li>
//                   Misuse any reporting, flagging, complaint, dispute, or appeals
//                   process, including by making groundless, vexatious, or
//                   frivolous submissions;
//                 </li>
//                 <li>
//                   Use the Service to view or listen to Content other than for
//                   personal, non-commercial use (for example, you may not
//                   publicly screen videos or stream music from the Service).
//                 </li>
//               </ul>
//             </div>
//             <div className="terms-header">Changes to the Service</div>
//             <div className="terms-section">
//               FLIXADDA is constantly changing and improving the Service. We may
//               also need to alter or discontinue the Service, or any part of it,
//               to make performance or security improvements, change functionality
//               and features, comply with the law, or prevent illegal activities
//               or abuse of our systems. These changes may affect all users, some
//               users, or even an individual user. Whenever reasonably possible,
//               we will provide notice when we discontinue or make material
//               changes to our Service that will have an adverse impact on its
//               use. However, you understand and agree that there may be times
//               when we make such changes without notice, such as where we feel it
//               is necessary to improve the security and operability of our
//               Service, prevent abuse, or comply with legal requirements.{" "}
//             </div>
//             <div className="terms-header"> Premium Services</div>
//             <div className="terms-section">
//               Certain sections of the Services may require payment in advance to
//               access features such as video/audio on demand or a
//               monthly/quarterly/half-yearly/annually subscription plan. Payments
//               for such services must be made through the payment options
//               available on our website. You may be directed to third-party
//               services, including payment gateways, to complete the payment. You
//               should review the terms and conditions of these third-party
//               services before using them.{" "}
//             </div>
//             <div className="terms-header">
//               {" "}
//               Payment, Refund, and Cancellation Policy{" "}
//             </div>
//             <div className="terms-header"> Payment: </div>
//             <div className="terms-section">
//               Payments are accepted only through the payment options provided on
//               the CiNet website. Prices for any Paid Service may change at any
//               time, and we do not provide price protection or refunds in the
//               event of a price reduction or promotional offering. You agree to
//               pay for any Paid Service that you order. Payment is deemed
//               received only when confirmed by our bank or payment gateway
//               service provider (collectively referred to as our payment system)
//               and will be reflected in the payment section of your account on
//               our services. Various factors may result in a payment you
//               processed not being credited to our payment system. If you
//               encounter any issues, you should contact your bank or write to us
//               at info@cinetshow.com.{" "}
//             </div>
//             <div className="terms-header">Taxes: </div>
//             <div className="terms-section">
//               "Taxes" refers to any duties, statutory levies, withholding taxes,
//               indirect taxes, or any other taxes associated with the
//               subscription of any Paid Service, including any related penalties
//               or interest. You are responsible for any Taxes and must pay for
//               Paid Service without any reduction for Taxes.{" "}
//             </div>
//             <div className="terms-section">
//               If we are obligated to collect or pay Taxes in connection with the
//               Paid Services, the Taxes will be charged to you. You must comply
//               with all applicable tax laws, including reporting and payment of
//               any Taxes arising from your subscription to Paid Services.
//             </div>
//             <div className="terms-header"> Refunds:</div>
//             <div className="terms-section">
//               Non-refundable transactions on purchase of any tickets and
//               subscription packages.
//             </div>
//             <div className="terms-header"> Subscription Cancellations:</div>
//             <div className="terms-section">
//               If you purchase a subscription to a Paid Service that
//               automatically renews, you may cancel the subscription any time
//               before the end of the current billing period, and the cancellation
//               will take effect in the next billing period. You will retain
//               access to the Paid Service from the time you cancel until the
//               start of the next billing period and will not receive a refund or
//               credit for any remaining days in your current billing period.
//             </div>
//             <div className="terms-header"> Free Trials:</div>
//             <div className="terms-section">
//               We may offer free trials to new Paid Service subscribers. If you
//               purchase a subscription to a Paid Service that includes a free
//               trial, you will receive that Paid Service free for the duration of
//               the free trial period. At the end of the applicable free trial
//               period, you will be charged the price of the subscription and will
//               continue to be charged until you cancel your subscription. To
//               avoid any charges, you must cancel b
//             </div>
//             <div className="terms-header"> Video Rentals and Purchases </div>
//             <div className="terms-section">
//               Certain types of Paid Services allow you to purchase or rent
//               individual items of content made available by third parties. You
//               may view rentals or purchases when online, with a broadband
//               connection, and logged onto your CiNet account. Due to
//               restrictions from our content providers, purchased or rented
//               content may not be available for purchase or playback outside of
//               the territory where you completed your transaction.{" "}
//             </div>
//             <div className="terms-header"> Rental Content: </div>
//             <div className="terms-section">
//               When you rent content, you may view such
//             </div>
//             <div className="terms-section">
//               content an unlimited number of times during the period displayed
//               at the time of your payment ("Viewing Period") and noted in your
//               confirmation email. Pausing, stopping, or rewinding rented content
//               will not extend your applicable Viewing Period. Each item of
//               rented content may have a different Viewing Period, so please
//               review the Viewing Period before you complete your transaction.
//               Rented content may only be viewed on one device at a time.
//             </div>
//             <div className="terms-header"> Paid Subscriptions: </div>
//             <div className="terms-section">
//               Certain types of Paid Services may allow you to access specific
//               content on the Services made available by third parties, each
//               containing a variety of content, in exchange for a one-time or
//               recurring subscription fee. When you purchase a subscription, you
//               will have access to stream any content currently available within
//               that subscription an unlimited number of times. Our partners may
//               periodically update the content available in the subscription, and
//               some content may either become unavailable or be made available
//               for free when this happens. You may only view content within a
//               subscription when you are online, with a broadband connection, and
//               logged into your CiNet account. Due to restrictions from our
//               content providers, subscription content may not be available for
//               playback outside of the territory where you completed your
//               transaction.{" "}
//             </div>
//             <div className="terms-header"> License </div>
//             <div className="terms-section">
//               Your use of the Services must be for personal, non-commercial use
//               only. You agree not to use any Service, in whole or in part, in
//               connection with any public presentation, even if no fee is charged
//               (except where such use would not constitute a copyright
//               infringement). You are receiving a non-exclusive license to the
//               Service, and all rights, titles, and interests in the Services
//               (including any content offered through the Services) not expressly
//               granted to you in these Terms are reserved by FLIXADDA and its
//               licensors. If FLIXADDA reasonably determines that you violated any
//               of the terms and conditions of the Paid Service Terms, your rights
//               under this Section will immediately terminate, and FLIXADDA may
//               terminate your right to use the Service and/or your CiNet account
//               without notice and without refund, if any, to you.{" "}
//             </div>
//             <div className="terms-header">
//               Account Suspension & Termination{" "}
//             </div>
//             <div className="terms-header">Terminations by You</div>
//             <div className="terms-section">
//               You may stop using the Service at any time. You have the option to
//               delete your account by visiting the profile section of your
//               account. You should copy and save your personal information before
//               deleting your account, as we do not preserve any data of the
//               deleted accounts. Any points, coins, or money standing to your
//               credit in your account shall lapse and cannot be restored after
//               the deletion of the account from our services.{" "}
//             </div>
//             <div className="terms-header">
//               {" "}
//               Terminations and Suspensions by FLIXADDA for Cause{" "}
//             </div>
//             <div className="terms-section">
//               FLIXADDA may suspend or terminate your use of the Services if (a)
//               you materially or repeatedly breach this Agreement; (b) we are
//               required to do so to comply with a legal requirement or a court
//               order; or (c) we believe there has been conduct that creates (or
//               could create) liability or harm to any user, other third party,
//               FLIXADDA, or our Affiliates.{" "}
//             </div>
//             <div className="terms-header">
//               {" "}
//               Terminations by FLIXADDA for Service Changes{" "}
//             </div>
//             <div className="terms-section">
//               FLIXADDA may terminate your use of the Services if FLIXADDA
//               believes, in its sole discretion, that the provision of the
//               Service to you is no longer commercially viable.{" "}
//             </div>
//             <div className="terms-header">
//               {" "}
//               Notice for Termination or Suspension{" "}
//             </div>
//             <div className="terms-section">
//               We will notify you with the reason for termination or suspension
//               by FLIXADDA unless we reasonably believe that doing so: (a) would
//               violate the law or the direction of a legal enforcement authority,
//               or would otherwise risk legal liability for FLIXADDA or our
//               Affiliates; (b) would compromise an investigation or the integrity
//               or operation of the Service; or (c) would cause harm to any user,
//               other third party, FLIXADDA, or our Affiliates. Where FLIXADDA is
//               terminating your use for Service changes, where reasonably
//               possible, you will be provided with sufficient time to copy your
//               Content from the Service or use any expired credit standing in
//               your account.{" "}
//             </div>
//             <div className="terms-header">
//               {" "}
//               Effect of Account Suspension or Termination{" "}
//             </div>
//             <div className="terms-section">
//               If you believe your CiNet account has been terminated in error,
//               you can write to us at legal@cinet.com.{" "}
//             </div>
//             <div className="terms-header"> Copyright</div>
//             <div className="terms-section">
//               By agreeing to create your account with CiNet, you represent that
//               you have read, understood, and agree to abide by the terms of the
//               Copyright Policy provided on the CiNet website.{" "}
//             </div>
//             <div className="terms-header"> Other Legal Terms</div>
//             <div className="terms-header">Warranty Disclaimer</div>
//             <div className="terms-section">
//               Other than as expressly stated in this Agreement or as required by
//               law, the Service is provided "as is" and FLIXADDA does not make
//               any specific commitments or warranties about the Service. For
//               example, we do not make any warranties about: (a) the content
//               provided through the Service; (b) the specific features of the
//               Service, or its accuracy, reliability, availability, or ability to
//               meet your needs.{" "}
//             </div>
//             <div className="terms-header">Limitation of Liability</div>
//             <div className="terms-section">
//               Except as required by applicable law, FLIXADDA, its affiliates,
//               officers, directors, employees, and agents will not be responsible
//               for any loss of profits, revenues, business opportunities,
//               goodwill, or anticipated savings; loss or corruption of data;
//               indirect or consequential loss; punitive damages caused by:{" "}
//             </div>
//             <div className="terms-section">
//               <ul>
//                 <li>Errors, mistakes, or inaccuracies on the Service;</li>
//                 <li>
//                   Personal injury or property damage resulting from your use of
//                   the Service;
//                 </li>
//                 <li>Any unauthorized use of the Service;</li>
//                 <li>Any interruption or cessation of the Service;</li>
//                 <li>
//                   Any viruses or malicious code transmitted to or through the
//                   Service by any third party;
//                 </li>
//                 <li>
//                   Any content, whether submitted by a user or FLIXADDA,
//                   including your use of Content; and/or
//                 </li>
//                 <li>The removal or unavailability of any Content.</li>
//               </ul>
//             </div>
//             <div className="terms-section">
//               This provision applies to any claim, regardless of whether the
//               claim asserted is based on warranty, contract, tort, or any other
//               legal theory.
//             </div>
//             <div className="terms-section">
//               To the extent permitted by applicable law, FLIXADDA and its
//               affiliates’ total liability for any claims arising from or
//               relating to the Service is limited to the greater of: (a) the
//               amount you spent on accessing the Services by making payment to
//               FLIXADDA in the 12 months before the date of your notice, in
//               writing to FLIXADDA, of the claim and (b) INR 5000, whichever is
//               higher.
//             </div>
//             <div className="terms-header"> Indemnity</div>
//             <div className="terms-section">
//               To the extent permitted by applicable law, you agree to defend,
//               indemnify, and hold harmless FLIXADDA, its Affiliates, officers,
//               directors, employees, and agents, from and against any and all
//               claims, damages, obligations, losses, liabilities, costs or debt,
//               and expenses (including but not limited to attorney's fees)
//               arising from: (i) your use of the Service; (ii) your violation of
//               any term of these Terms of Service; (iii) your violation of any
//               third party right, including without limitation any copyright,
//               property, or privacy right; or (iv) any claim that your Content
//               caused damage to a third party. This defense and indemnification
//               obligation will survive this Agreement and your use of the
//               Service.{" "}
//             </div>
//             <div className="terms-header"> Third-Party Links</div>
//             <div className="terms-section">
//               The Service may contain links to third-party websites and online
//               services that are not owned or controlled by FLIXADDA. FLIXADDA
//               has no control over and assumes no responsibility for such
//               websites and online services. Be aware when you leave the Service;
//               we suggest you read the terms and privacy policy of each
//               third-party website and online service that you visit.{" "}
//             </div>
//             <div className="terms-header"> User Comments and Feedback </div>
//             <div className="terms-section">
//               If, at our request, you send certain specific submissions (for
//               example, contest entries) or without a request from us you send
//               creative ideas, suggestions, proposals, plans, or other materials,
//               whether online, by email, by postal mail, or otherwise
//               (collectively, 'comments'), you agree that we may, at any time,
//               without restriction, edit, copy, publish, distribute, translate,
//               and otherwise use in any medium any comments that you forward to
//               us or post online. We are and shall be under no obligation (1) to
//               maintain any comments in confidence; (2) to pay compensation for
//               any comments; or (3) to respond to any comments.{" "}
//             </div>
//             <div className="terms-section">
//               We may, but have no obligation to, monitor, edit, or remove
//               content that we determine in our sole discretion is unlawful,
//               offensive, threatening, libelous, defamatory, pornographic,
//               obscene, or otherwise objectionable or violates any party's
//               intellectual property or these Terms of Service.
//             </div>
//             <div className="terms-section">
//               You agree that your comments will not violate any right of any
//               third party, including copyright, trademark, privacy, personality,
//               or other personal or proprietary right. You further agree that
//               your comments will not contain libelous or otherwise unlawful,
//               abusive, or obscene material, or contain any computer virus or
//               other malware that could in any way affect the operation of the
//               Service or any related website. You may not use a false email
//               address, pretend to be someone other than yourself, or otherwise
//               mislead us or third parties as to the origin of any comments. You
//               are solely responsible for any comments you make and their
//               accuracy. We take no responsibility and assume no liability for
//               any comments posted by you or any third party.
//             </div>
//             <div className="terms-header"> About This Agreement </div>
//             <div className="terms-header"> Force Majeure </div>
//             <div className="terms-section">
//               FLIXADDA shall not be deemed in default for any delay or failure
//               to fulfill any obligation hereunder or provide any services or
//               process the refund so long as and to the extent that any delay or
//               failure in the fulfillment of such obligation is prevented,
//               frustrated, hindered, or delayed as a consequence of circumstances
//               of a Force Majeure event. The term Force Majeure shall be
//               understood to mean and include any event beyond the control of
//               FLIXADDA, including acts of God, acts of civil or military
//               authority, server failure, internet failure, major power
//               breakdown, embargoes, epidemics, pandemics, war, riots,
//               insurrections, fires, explosions, earthquakes, hurricanes,
//               blizzards, floods, terrorism, sudden and unusually extreme weather
//               conditions, and/or organized labor problems.{" "}
//             </div>
//             <div className="terms-header"> Modifying this Agreement </div>
//             <div className="terms-section">
//               We may modify this Agreement, for example, to reflect changes to
//               our Service or for legal, regulatory, or security reasons.
//               FLIXADDA will provide reasonable advance notice of any material
//               modifications to this Agreement and the opportunity to review
//               them,{" "}
//             </div>
//             <div className="terms-section">
//               except that, where legally permitted, modifications addressing
//               newly available features of the Service or modifications made for
//               legal reasons may be effective immediately without notice.
//               Modifications to this Agreement will only apply going forward. If
//               you do not agree to the modified terms, you should remove any
//               Content you have uploaded and discontinue your use of the Service.
//             </div>
//             <div className="terms-header">
//               Severance
//               <div className="terms-section">
//                 If it turns out that a particular term of this Agreement is not
//                 enforceable for any reason, this will not affect any other
//                 terms.{" "}
//               </div>
//             </div>
//             <div className="terms-header">No Waiver</div>
//             <div className="terms-section">
//               If you fail to comply with this Agreement and we do not take
//               immediate action, this does not mean that we are giving up any
//               rights that we may have (such as the right to take action in the
//               future).
//             </div>{" "}
//             <div className="terms-header">Interpretation </div>
//             <div className="terms-section">
//               In these terms, "include" or "including" means "including but not
//               limited to," and any examples we give are for illustrative
//               purposes.{" "}
//             </div>{" "}
//             <div className="terms-header">Governing Law </div>
//             <div className="terms-section">
//               Indian law will govern all disputes arising out of or relating to
//               these terms or the Service, regardless of conflict of laws rules.
//               These disputes will be resolved exclusively in the Courts at
//               Gurgaon, Haryana, and you and FLIXADDA consent to personal
//               jurisdiction in those courts.{" "}
//             </div>{" "}
//             <div className="terms-header">Arbitration </div>
//             <div className="terms-section">
//               All disputes, claims, suits, and actions arising out of this
//               Agreement, or its validity will be finally decided in accordance
//               with the provisions of the Indian Arbitration and Conciliation
//               Act, 1996. The venue for arbitration shall be at Bengaluru,
//               Karnataka, India. To settle the dispute arising under this
//               agreement, the dispute shall be referred to a sole arbitrator to
//               be appointed with the consent of both parties. The language of
//               arbitration shall be English. The award of the tribunal shall be
//               final.{" "}
//             </div>{" "}
//           </div>
//         </Container>
//       </div>
//     </>
//   );
// };

// export default TermsAndCondition;
import { Container } from "@mui/material";
import React, { useEffect } from "react";
import "../../scss/TermsAndConditions.scss";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="terms-container">
        <Container>
          <div className="terms-header">Terms & Conditions</div>

          <div className="terms-content">
            <div className="terms-header">Introduction</div>
            <div className="terms-header"> Who We Are and What We Do</div>

            <div className="terms-section">
              These terms and conditions outline the practices of FLIXADDA LLP
              (collectively, "FLIXADDA," "we," "us," or "our") and its product
              "CINETSHOW." We provide online on-demand films, videos, audios,
              and video/audio hosting, sharing, and related services through our
              owned-and-operated websites, including cinetshow.com, as well as
              our branded applications for mobile and connected devices
              (collectively, the "services"). Video/Audios and other
              entertainment materials hosted on our services are collectively
              referred to as "Contents."
            </div>

            <div className="terms-header">Applicable Terms</div>
            <div className="terms-section">
              Your use of the Service is subject to these terms, our Privacy
              Policy, and Copyright Policy, which may be updated (together, this
              "Agreement"). Any other links or references provided in these
              terms are for informational purposes only and are not part of the
              Agreement.
            </div>

            <div className="terms-section">
              Please read this Agreement carefully and ensure you understand it.
              If you do not understand the Agreement or do not accept any part
              of it, you may not use the Service.
            </div>
            <div className="terms-section">
              By accepting this Agreement, you affirm that you are 18 years of
              age or older and are fully competent to enter into this Agreement
              and to abide by and comply with its terms. If you are under 18
              years of age, you must have permission from a parent or guardian
              as provided below.
            </div>

            <div className="terms-header">Permission by Parent or Guardian</div>
            <div className="terms-section">
              If you are considered a minor in your country, you represent that
              you have your parent or guardian's permission to use the Service.
              Please have them read this Agreement with you. If you are a parent
              or legal guardian of a minor in your country, by allowing your
              child to use the Service, you are subject to the terms of this
              Agreement and responsible for your child's activity on the
              Service.
            </div>

            <div className="terms-header">Businesses</div>
            <div className="terms-section">
              If you are using the Service on behalf of a company or
              organization, you represent that you have the authority to act on
              behalf of that entity and that such entity accepts this Agreement.
            </div>

            <div className="terms-header">Account Access</div>
            <div className="terms-section">
              You exercise control over your cinetshow user account through your
              mobile or google account. The cinetshow service and any content
              viewed through the service are for your personal and
              non-commercial use only and must not be shared with anyone. Be
              cautious of any communication requesting that you submit credit
              card or other account information. Providing your information in
              response to such communications can result in identity theft.
              Always access your sensitive account information by going directly
              to the cinetshow website and not through a hyperlink in an email
              or any other electronic communication, even if it looks official.
              FLIXADDA reserves the right to place any account on hold anytime,
              with or without notification to the subscriber or member, to
              protect itself and its affiliates from what it believes to be
              fraudulent activity.
            </div>

            <div className="terms-header">Your Use of the Service</div>
            <div className="terms-header">Content on the Service</div>
            <div className="terms-section">
              The content on the Service includes videos, audio (for example,
              music and other sounds) (collectively, "Content"). Content is the
              responsibility of the person or entity that provides it to the
              Service. FLIXADDA is under no obligation to verify the content for
              compliance with any law, copyright, morality, religion, or any
              government or court orders. If you see any Content, you believe
              does not comply with this Agreement, including violations of
              copyright or the law, you can report it to us.
            </div>

            <div className="terms-header">Your Information</div>
            <div className="terms-section">
              Our Privacy Policy explains how we treat your personal data and
              protect your privacy when you use the Service.
            </div>

            <div className="terms-section">
              The following restrictions apply to your use of the Service. You
              are not allowed to:
              <ul>
                <li>
                  Access, reproduce, download, distribute, transmit, broadcast,
                  display, sell, license, alter, modify, or otherwise use any
                  Content except: (a) as expressly authorized by FLIXADDA or the
                  Content Owner; or (b) with prior written permission from
                  FLIXADDA or the Content Owner;
                </li>
                <li>
                  Circumvent, disable, fraudulently engage with, or otherwise
                  interfere with any part of the Service (or attempt to do any
                  of these things), including security-related features or
                  features that (a) allow the copying or other use of Content or
                  (b) limit the use of the Service or Content;
                </li>
                <li>
                  Access the Service using any automated means (such as robots,
                  botnets, or scrapers) except with FLIXADDA's prior written
                  permission;
                </li>
                <li>
                  Collect or harvest any information that might identify a
                  person (for example, usernames), unless permitted by that
                  person;
                </li>
                <li>
                  Use the Service to distribute unsolicited promotional or
                  commercial content or other unwanted or mass solicitations;
                </li>
                <li>
                  Misuse any reporting, flagging, complaint, dispute, or appeals
                  process, including by making groundless, vexatious, or
                  frivolous submissions;
                </li>
                <li>
                  Use the Service to view or listen to Content other than for
                  personal, non-commercial use (for example, you may not
                  publicly screen videos or stream music from the Service).
                </li>
              </ul>
            </div>

            <div className="terms-header">Changes to the Service</div>
            <div className="terms-section">
              FLIXADDA is constantly changing and improving the Service. We may
              also need to alter or discontinue the Service, or any part of it,
              to make performance or security improvements, change functionality
              and features, comply with the law, or prevent illegal activities
              or abuse of our systems. These changes may affect all users, some
              users, or even an individual user. Whenever reasonably possible,
              we will provide notice when we discontinue or make material
              changes to our Service that will have an adverse impact on its
              use. However, you understand and agree that there may be times
              when we make such changes without notice, such as where we feel it
              is necessary to improve the security and operability of our
              Service, prevent abuse, or comply with legal requirements.
            </div>

            <div className="terms-header">Premium Services</div>
            <div className="terms-section">
              Certain sections of the Services may require payment in advance to
              access features such as video/audio on demand or a
              monthly/quarterly/half-yearly/annually subscription plan. Payments
              for such services must be made through the payment options
              available on our website. You may be directed to third-party
              services, including payment gateways, to complete the payment. You
              should review the terms and conditions of these third-party
              services before using them.
            </div>

            <div className="terms-header">
              Payment, Refund, and Cancellation Policy
            </div>
            <div className="terms-section">
              <b>Payment:</b> Payments are accepted only through the payment
              options provided on the cinetshow website. Prices for any Paid
              Service may change at any time, and we do not provide price
              protection or refunds in the event of a price reduction or
              promotional offering. You agree to pay for any Paid Service that
              you order. Payment is deemed received only when confirmed by our
              bank or payment gateway service provider (collectively referred to
              as our payment system) and will be reflected in the payment
              section of your account on our services. Various factors may
              result in a payment you processed not being credited to our
              payment system. If you encounter any issues, you should contact
              your bank or write to us at info@cinetshow.com.
              <br />
              <b>Taxes:</b> "Taxes" refers to any duties, statutory levies,
              withholding taxes, indirect taxes, or any other taxes associated
              with the subscription of any Paid Service, including any related
              penalties or interest. You are responsible for any Taxes and must
              pay for Paid Service without any reduction for Taxes. If we are
              obligated to collect or pay Taxes in connection with the Paid
              Services, the Taxes will be charged to you. You must comply with
              all applicable tax laws, including reporting and payment of any
              Taxes arising from your subscription to Paid Services.
              <br />
              <b>Refunds:</b> Non-refundable transactions on the purchase of
              tickets and subscription packages.
              <br />
              <b>Subscription Cancellations:</b> If you purchase a subscription
              to a Paid Service that automatically renews, you may cancel the
              subscription any time before the end of the current billing
              period, and the cancellation will take effect in the next billing
              period. You will retain access to the Paid Service from the time
              you cancel until the start of the next billing period and will not
              receive a refund or credit for any remaining days in your current
              billing period.
              <br />
              <b>Free Trials: </b> We may offer free trials to new Paid Service
              subscribers. If you purchase a subscription to a Paid Service that
              includes a free trial, you will receive that Paid Service free for
              the duration of the free trial period. At the end of the
              applicable free trial period, you will be charged the price of the
              subscription and will continue to be charged until you cancel your
              subscription. To avoid any charges, you must cancel before the end
              of the free trial period.
            </div>

            <div className="terms-header">Video Rentals and Purchases</div>
            <div className="terms-section">
              Certain types of Paid Services allow you to purchase or rent
              individual items of content made available by third parties. You
              may view rentals or purchases when online, with a broadband
              connection, and logged onto your cinetshow account. Due to
              restrictions from our content providers, purchased or rented
              content may not be available for purchase or playback outside of
              the territory where you completed your transaction.
            </div>

            <div className="terms-header">Rental Content</div>
            <div className="terms-section">
              When you rent content, you may view such content according to
              displayed at the time of your payment ("Viewing Period"). Pausing,
              stopping, or rewinding rented content will not extend your
              applicable Viewing Period. Each item of rented content may have a
              different Viewing Period, so please review the Viewing Period
              before you complete your transaction. Rented content may only be
              viewed on one device at a time.
            </div>

            <div className="terms-header">Paid Subscriptions</div>
            <div className="terms-section">
              Certain types of Paid Services may allow you to access specific
              content on the Services made available by third parties, each
              containing a variety of content, in exchange for a one-time or
              recurring subscription fee. When you purchase a subscription, you
              will have access to stream any content currently available within
              that subscription an unlimited number of times. Our partners may
              periodically update the content available in the subscription, and
              some content may either become unavailable or be made available
              for free when this happens. You may only view content within a
              subscription when you are online, with a broadband connection, and
              logged into your cinetshow account. Due to restrictions from our
              content providers, subscription content may not be available for
              playback outside of the territory where you completed your
              transaction.
            </div>

            <div className="terms-header">License</div>
            <div className="terms-section">
              Your use of the Services must be for personal, non-commercial use
              only. You agree not to use any Service, in whole or in part, in
              connection with any public presentation, even if no fee is charged
              (except where such use would not constitute a copyright
              infringement). You are receiving a non-exclusive license to the
              Service, and all rights, titles, and interests in the Services
              (including any content offered through the Services) not expressly
              granted to you in these Terms are reserved by FLIXADDA and its
              licensors. If FLIXADDA reasonably determines that you violated any
              of the terms and conditions of the Paid Service Terms, your rights
              under this Section will immediately terminate, and FLIXADDA may
              terminate your right to use the Service and/or your cinetshow
              account without notice and without refund, if any, to you.
            </div>

            <div className="terms-header">Account Suspension & Termination</div>
            <div className="terms-section">
              <b>Terminations by You:</b> You may stop using the Service at any
              time. You have the option to delete your account by visiting the
              profile section of your account. You should copy and save your
              personal information before deleting your account, as we do not
              preserve any data of the deleted accounts. Any points, coins, or
              money standing to your credit in your account shall lapse and
              cannot be restored after the deletion of the account from our
              services.
              <br />
              <b>Terminations and Suspensions by FLIXADDA for Cause:</b>{" "}
              FLIXADDA may suspend or terminate your use of the Services if (a)
              you materially or repeatedly breach this Agreement; (b) we are
              required to do so to comply with a legal requirement or a court
              order; or (c) we believe there has been conduct that creates (or
              could create) liability or harm to any user, other third party,
              FLIXADDA, or our Affiliates.
              <br />
              <b>Terminations by FLIXADDA for Service Changes :</b> FLIXADDA may
              terminate your use of the Services if FLIXADDA believes, in its
              sole discretion, that the provision of the Service to you is no
              longer commercially viable.
              <br />
              <b>Notice for Termination or Suspension :</b> We will notify you
              with the reason for termination or suspension by FLIXADDA unless
              we reasonably believe that doing so: (a) would violate the law or
              the direction of a legal enforcement authority, or would otherwise
              risk legal liability for FLIXADDA or our Affiliates; (b) would
              compromise an investigation or the integrity or operation of the
              Service; or (c) would cause harm to any user, other third party,
              FLIXADDA, or our Affiliates. Where FLIXADDA is terminating your
              use for Service changes, where reasonably possible, you will be
              provided with sufficient time to copy your Content from the
              Service or use any expired credit standing in your account.
              <br />
              <b>Effect of Account Suspension or Termination :</b> If you
              believe your cinetshow account has been terminated in error, you
              can write to us at legal@cinetshow.com.
            </div>

            <div className="terms-header">Copyright</div>
            <div className="terms-section">
              By agreeing to create your account with cinetshow, you represent
              that you have read, understood, and agree to abide by the terms of
              the Copyright Policy provided on the cinetshow.com website.
            </div>

            <div className="terms-header">Other Legal Terms</div>

            <div className="terms-header">Warranty Disclaimer</div>
            <div className="terms-section">
              Other than as expressly stated in this Agreement or as required by
              law, the Service is provided "as is" and FLIXADDA does not make
              any specific commitments or warranties about the Service. For
              example, we do not make any warranties about: (a) the content
              provided through the Service; (b) the specific features of the
              Service, or its accuracy, reliability, availability, or ability to
              meet your needs.
            </div>

            <div className="terms-header">Limitation of Liability</div>
            <div className="terms-section">
              Except as required by applicable law, FLIXADDA, its affiliates,
              officers, directors, employees, and agents will not be responsible
              for any loss of profits, revenues, business opportunities,
              goodwill, or anticipated savings; loss or corruption of data;
              indirect or consequential loss; punitive damages caused by:
            </div>
            <ul>
              <li> Errors, mistakes, or inaccuracies on the Service; </li>
              <li>
                {" "}
                Personal injury or property damage resulting from your use of
                the Service;
              </li>
              <li> Any unauthorized use of the Service; </li>
              <li> Any interruption or cessation of the Service; </li>
              <li>
                {" "}
                Any viruses or malicious code transmitted to or through the
                Service by any third party;
              </li>
              <li>
                {" "}
                Any content, whether submitted by a user or FLIXADDA, including
                your use of Content; and/or
              </li>
              <li> The removal or unavailability of any Content. </li>
            </ul>
            <div className="terms-section">
              This provision applies to any claim, regardless of whether the
              claim asserted is based on warranty, contract, tort, or any other
              legal theory.
            </div>
            <div className="terms-section">
              To the extent permitted by applicable law, FLIXADDA and its
              affiliates’ total liability for any claims arising from or
              relating to the Service is limited to the greater of: (a) the
              amount you spent on accessing the Services by making payment to
              FLIXADDA in the 12 months before the date of your notice, in
              writing to FLIXADDA, of the claim and (b) INR 5000, whichever is
              higher.
            </div>

            <div className="terms-header">Indemnity</div>
            <div className="terms-section">
              To the extent permitted by applicable law, you agree to defend,
              indemnify, and hold harmless FLIXADDA, its Affiliates, officers,
              directors, employees, and agents, from and against any and all
              claims, damages, obligations, losses, liabilities, costs or debt,
              and expenses (including but not limited to attorney's fees)
              arising from: (i) your use of the Service; (ii) your violation of
              any term of these Terms of Service; (iii) your violation of any
              third party right, including without limitation any copyright,
              property, or privacy right; or (iv) any claim that your Content
              caused damage to a third party. This defense and indemnification
              obligation will survive this Agreement and your use of the
              Service.
            </div>

            <div className="terms-header">Third-Party Links</div>
            <div className="terms-section">
              The Service may contain links to third-party websites and online
              services that are not owned or controlled by FLIXADDA. FLIXADDA
              has no control over and assumes no responsibility for such
              websites and online services. Be aware when you leave the Service;
              we suggest you read the terms and privacy policy of each
              third-party website and online service that you visit.
            </div>

            <div className="terms-header">User Comments and Feedback</div>
            <div className="terms-section">
              If, at our request, you send certain specific submissions or
              without a request from us you send creative ideas, suggestions,
              proposals, plans, or other materials, whether online, by email, by
              postal mail, or otherwise (collectively, 'comments'), you agree
              that we may, at any time, without restriction, edit, copy,
              publish, distribute, translate, and otherwise use in any medium
              any comments that you forward to us or post online. We are and
              shall be under no obligation (1) to maintain any comments in
              confidence; (2) to pay compensation for any comments; or (3) to
              respond to any comments.
            </div>
            <div className="terms-section">
              We may, but have no obligation to, monitor, edit, or remove
              content that we determine in our sole discretion is unlawful,
              offensive, threatening, libelous, defamatory, pornographic,
              obscene, or otherwise objectionable or violates any party's
              intellectual property or these Terms of Service.
            </div>
            <div className="terms-section">
              You agree that your comments will not violate any right of any
              third party, including copyright, trademark, privacy, personality,
              or other personal or proprietary right. You further agree that
              your comments will not contain libelous or otherwise unlawful,
              abusive, or obscene material, or contain any computer virus or
              other malware that could in any way affect the operation of the
              Service or any related website. You may not use a false email
              address, pretend to be someone other than yourself, or otherwise
              mislead us or third parties as to the origin of any comments. You
              are solely responsible for any comments you make and their
              accuracy. We take no responsibility and assume no liability for
              any comments posted by you or any third party.
            </div>
            <div className="terms-header">About This Agreement </div>
            <div className="terms-section">
              FLIXADDA shall not be deemed in default for any delay or failure
              to fulfill any obligation hereunder or provide any services or
              process the refund so long as and to the extent that any delay or
              failure in the fulfillment of such obligation is prevented,
              frustrated, hindered, or delayed as a consequence of circumstances
              of any event beyond the control of FLIXADDA, including acts of
              God, acts of civil or military authority, server failure, internet
              failure, major power breakdown, embargoes, epidemics, pandemics,
              war, riots, insurrections, fires, explosions, earthquakes,
              hurricanes, blizzards, floods, terrorism, sudden and unusually
              extreme weather conditions, and/or organized labor problems.
            </div>

            <div className="terms-header">Modifying this Agreement</div>
            <div className="terms-section">
              We may modify this Agreement, for example, to reflect changes to
              our Service or for legal, regulatory, or security reasons.
              FLIXADDA will provide reasonable advance notice of any material
              modifications to this Agreement and the opportunity to review
              them,
            </div>
            <div className="terms-section">
              except that, where legally permitted, modifications addressing
              newly available features of the Service or modifications made for
              legal reasons may be effective immediately without notice.
              Modifications to this Agreement will only apply going forward. If
              you do not agree to the modified terms, you should remove any
              Content you have uploaded and discontinue your use of the Service.
            </div>

            <div className="terms-header">Severance</div>
            <div className="terms-section">
              If it turns out that a particular term of this Agreement is not
              enforceable for any reason, this will not affect any other terms.
            </div>

            <div className="terms-header">No Waiver</div>
            <div className="terms-section">
              If you fail to comply with this Agreement and we do not take
              immediate action, this does not mean that we are giving up any
              rights that we may have (such as the right to take action in the
              future).
            </div>
            <div className="terms-header">Interpretation </div>
            <div className="terms-section">
              In these terms, "include" or "including" means "including but not
              limited to," and any examples we give are for illustrative
              purposes.
            </div>

            <div className="terms-header">Governing Law</div>
            <div className="terms-section">
              Indian law will govern all disputes arising out of or relating to
              these terms or the Service, regardless of conflict of laws rules.
              These disputes will be resolved exclusively in the Courts at
              Bengaluru, Karnataka and you and FLIXADDA consent to personal
              jurisdiction in those courts.
            </div>

            <div className="terms-header">Arbitration</div>
            <div className="terms-section">
              All disputes, claims, suits, and actions arising out of this
              Agreement, or its validity will be finally decided in accordance
              with the provisions of the Indian Arbitration and Conciliation
              Act, 1996. The venue for arbitration shall be at Bengaluru,
              Karnataka, India. To settle the dispute arising under this
              agreement, the dispute shall be referred to a sole arbitrator to
              be appointed with the consent of both parties. The language of
              arbitration shall be English. The award of the tribunal shall be
              final.
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TermsAndConditions;
