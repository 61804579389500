import React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Strings from "../string";
import AvatarImg from "../../images/Avatar.svg";
import SearchCard from "./searchCard";
import Logo from "../../images/cinetshow2.svg";

export default function Navbarforlogin() {
  const navigate = useNavigate();

  const storedUserName =
    localStorage.getItem("user_name") || Strings.LOGIN_SINGUP;
  const isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));

  const handleNavigateTologin = () => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "#1E1E1E",
          position: "sticky",
          padding: {
            xs: "0",
            sm: "0",
            md: "12px 60px",
            xl: "12px 60px",
          },
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                mr: 5,
                display: { xs: "none", md: "flex" },
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/home");
              }}
            >
              <img src={Logo} alt="Logo" className="logo-image" />
            </Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <SearchCard />
              <Box>
                <div
                  onClick={handleNavigateTologin}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <IconButton sx={{ p: 0 }}>
                    <Avatar
                      style={{ marginRight: "12px" }}
                      alt="Avatar"
                      src={AvatarImg}
                    />
                  </IconButton>
                  <Typography
                    sx={{
                      color: "#FED530",
                      fontFamily: "DM Sans",
                      fontSize: "16px",
                      fontWeight: "700",
                      textWrap: "nowrap",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                      },
                    }}
                  >
                    {storedUserName}
                  </Typography>
                </div>
              </Box>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
